import React, { useEffect, useRef, useState } from 'react';
import { ToastComponent } from '@syncfusion/ej2-react-notifications';
import { TreeGridComponent, Inject as InjectToolbar, Toolbar, ExcelExport } from '@syncfusion/ej2-react-treegrid';
import moment from 'moment';
import { Row } from 'react-bootstrap';
import { formatUTCDate } from 'common/widgets';
import { requests } from 'components/AvaEzm/AxiosApi';
import {
    CapRatesColumns,
    textDateValidation,
} from 'components/ProviderData/IpaAdministration/atoms/IpaAdministration.Metadata';
import { CapRateDetail, IPADetail } from 'components/ProviderData/IpaAdministration/atoms/Models';
import { CapRatesForm } from 'components/ProviderData/IpaAdministration/organisms/CapRates/CapRatesForm';
import { CapSpecialRateForm } from 'components/ProviderData/IpaAdministration/organisms/CapRates/CapSpecialRateForm';
import { CapTieredRates } from 'components/ProviderData/IpaAdministration/organisms/CapRates/CapTieredRates';
import { submitCapRateService } from 'components/ProviderData/subjects/IpaAdministrationService';
import { dialogService } from 'subjects/common/DialogService';

type CapRatesProps = {
    ipaCode: string;
    ipaId?: number;
    ipa: IPADetail;
    isReadOnlyViewer: boolean;
};
interface CapRateData {
    id?: number;
    sequence?: number;
    ipaCode?: string;
    isCapRate: boolean;
    isSpecialRate: boolean;
}

const CapRatesTab = (props: CapRatesProps) => {
    const { ipaCode, ipa, isReadOnlyViewer } = props;
    const { effDate, endDate, companyCode, ipaPhone, ipaPhone2 } = ipa;
    const [dataSource, setDataSource] = useState<CapRateDetail[]>([]);
    const toastRef = useRef<ToastComponent>(null);
    const treeGridRef = useRef<TreeGridComponent>(null);
    const [capRate, setCapRate] = useState<CapRateData>({ isCapRate: true } as CapRateData);
    const [capRateDetail, setCapRateDetail] = useState<CapRateDetail>({} as CapRateDetail);
    const [maxEndDate, setMaxEndDate] = useState<Date>();
    const [prevDatesCap, setPrevDatesCap] = useState<{ effDate: Date | string; endDate: Date | string }[] | null>(null);
    const formatData = (d) => ({
        ...d,
        effDate: formatUTCDate(d.effDate),
        endDate: formatUTCDate(d.endDate),
        createDate: formatUTCDate(d.createDate || d.createdDate),
        updateDt: formatUTCDate(d.updateDt || d.updatedDate),
        sequence: d.memberCountStart ? `Range (${d.memberCountStart} - ${d.memberCountEnd})` : d.sequence,
        sequenceNumber: d.sequence,
    });
    const fetchCapRateDetail = async (_sequence) => {
        const capRateDetail = await requests.get<CapRateDetail>(
            `/api/CapRate/GetCapRateDetail?ipaCode=${ipaCode}&sequence=${_sequence}`,
        );
        return capRateDetail;
    };
    const updateDataSource = async () => {
        const searchUrlCapRates = `/api/CapRate/GetCapRates?IpaCode=${ipaCode}`;
        const searchUrlCapSubRates = `/api/CapRate/GetCapSubRates?IpaCode=${ipaCode}`;
        const data = await requests.get<any>(searchUrlCapRates);
        for (const _capRate of data) {
            const detail = await fetchCapRateDetail(_capRate.sequence);
            _capRate.detail = detail;
        }
        const capSubRates = await requests.get<any>(searchUrlCapSubRates);
        const formattedData = data.map(formatData).map((d) => ({
            ...d,
            capSubRates: capSubRates
                .filter((t) => t.capId === d.id)
                .map(formatData)
                .sort((a, b) => a.capSubRateType - b.capSubRateType),
        }));
        setDataSource(formattedData);

        const maxEffEndDate = new Date(
            Math.max(
                ...data.map((d) => {
                    return new Date(d.endDate);
                }),
            ),
        );
        setMaxEndDate(maxEffEndDate);
    };

    useEffect(() => {
        updateDataSource();
        if (treeGridRef && isReadOnlyViewer) {
            // Disable toolbar items.
            treeGridRef.current?.toolbarModule.enableItems(
                [treeGridRef.current.element.id + '_gridcontrol_Create'],
                false,
            );
        }

        const service = submitCapRateService.get$().subscribe(({ type, capRateData }) => {
            if (type === 'submit' || type === 'deactivate' || type === 'activate') {
                const typesText = {
                    submit: 'Saved',
                    activate: 'Activated',
                    deactivate: 'Deactivated',
                };
                toastRef.current?.show({
                    title: 'Success !',
                    content: `Cap Rate ${typesText[type]} Successfully`,
                    cssClass: 'e-toast-success',
                });
                updateDataSource();
                setCapRate({} as CapRateData);
            } else if (type === 'cancel') {
                setCapRate({} as CapRateData);
                dialogService.close();
            } else if (type === 'deleteCapSpecialRate') {
                setCapRate({} as CapRateData);
                toastRef.current?.show({
                    title: 'Delete !',
                    content: `Cap Special Rate was deleted Successfully`,
                    cssClass: 'e-toast-success',
                });
                updateDataSource();
                dialogService.close();
            } else if (type === 'addCapTier' || type === 'addCapSpecialRate' || type === 'copyCapRate') {
                const _capRateData = {
                    ...capRateData,
                    sequence: type === 'addCapSpecialRate' ? capRateData.sequence : 0,
                    isCopy: type === 'copyCapRate',
                } as CapRateData;
                setCapRate(_capRateData);
                showCreateDialog(_capRateData, true);
            }
        });
        return () => {
            service.unsubscribe();
        };
    }, [companyCode]);

    const setPreviousCapDates = (row) => {
        const previousCapRate = dataSource
            .sort((a, b) => moment(b.endDate).unix() - moment(a.endDate).unix())
            .filter((d) => d.id !== row.id);
        let extractDates = {} as CapRateDetail[];
        if (row.parentItem) {
            extractDates = [row.parentItem];
        } else if (previousCapRate) {
            extractDates = previousCapRate;
        }
        setPrevDatesCap(extractDates);
        return extractDates;
    };

    const setCapRateDetailRow = (row: any) => {
        setCapRate({
            id: row.id ? row.id : row.capId,
            sequence: row.sequenceNumber,
            isCapRate: row.hasChildRecords,
            isSpecialRate: row.capSubRateType == 'Special Rates' ? true : false,
            effDate: row.effDate,
            endDate: row.endDate,
            ...row.detail,
        } as CapRateData);
        setCapRateDetail(row.detail as CapRateDetail);
        setPreviousCapDates(row);
    };

    const FormCapRates = ({ capRate, prevDatesCap }) => {
        return capRate.isCapRate ? (
            <CapRatesForm
                sequence={capRate?.sequence}
                ipaCode={ipaCode}
                companyCode={companyCode}
                id={capRate?.id}
                capRate={capRate}
                ipaInfo={{ dates: { effDate, endDate }, ipaPhone, ipaPhone2 }}
                prevDatesCap={prevDatesCap}
                maxEndDate={maxEndDate}
                isReadOnlyViewer={isReadOnlyViewer}
            />
        ) : capRate.isSpecialRate ? (
            <CapSpecialRateForm
                ipaDetails={ipa}
                capRateId={capRate?.id}
                capSpecialRateId={capRate?.addNew ? 0 : capRate.id}
                dates={{ effDate: capRate.effDate, endDate: capRate.endDate }}
                isReadOnlyViewer={isReadOnlyViewer}
            />
        ) : (
            <CapTieredRates
                sequence={capRate?.sequence}
                ipaCode={ipaCode ? ipaCode : capRate.ipaCode}
                capId={capRate?.id}
                dates={{ effDate: capRate.effDate, endDate: capRate.endDate }}
                prevDatesCap={prevDatesCap?.find((d) => d)}
                isReadOnlyViewer={isReadOnlyViewer}
            />
        );
    };

    const toolbarClick = ({ item }) => {
        if (item.text === 'Create') {
            submitCapRateService.add();
            setCapRate({ sequence: 0, isCapRate: true } as CapRateData);
            showCreateDialog({ sequence: 0, isCapRate: true } as CapRateData, false);
        } else {
            const columnsToRemove = ['sequence', 'active', 'effDatef', 'endDatef'];
            const columnsToExport = CapRatesColumns.filter((c) => !columnsToRemove.includes(c.field)) as any[];
            treeGridRef.current?.excelExport({
                includeHiddenColumn: true,
                fileName: 'CapRates-report.xlsx',
                columns: columnsToExport,
                header: {
                    headerRows: 1,
                    rows: [
                        {
                            cells: [
                                {
                                    colSpan: 12,
                                    value: 'Cap Rates Report',
                                    style: {
                                        fontColor: '#ffffff',
                                        backColor: '#00b3e3',
                                        fontSize: 25,
                                        hAlign: 'Center',
                                        bold: true,
                                    },
                                },
                            ],
                        },
                    ],
                },
            });
        }
    };

    const showCreateDialog = (_capRate, isCapRateCreation) => {
        let _prevDatesCap = setPreviousCapDates({
            sequenceNumber: Infinity,
            isCapRate: _capRate.isCapRate,
        });
        if (isCapRateCreation) {
            const { effDate, endDate } = _capRate;
            _prevDatesCap = [{ effDate, endDate }] as CapRateDetail[];
        }
        const dialog: any = {};
        if (_capRate.isCapRate) {
            dialog.header = _capRate.isCopy ? 'Copy Cap Rate' : 'Create Cap Rate';
        } else {
            if (_capRate.isSpecialRate) {
                dialog.header = 'Create Cap Special Rate';
            } else {
                dialog.header = 'Create Cap Tiered Rate';
            }
        }
        const ipaEndDate = moment(ipa.endDate).startOf('day');
        const PrevEndDate = moment(_prevDatesCap.find((f) => f)?.endDate).startOf('day');
        const capRateEffDate = moment(_capRate.maxEndDate).startOf('day');
        dialog.buttons = [];
        dialog.content = () =>
            (ipaEndDate.isSame(PrevEndDate) && _capRate.isCapRate) ||
            (ipaEndDate.isSame(capRateEffDate) && _capRate?.isCopy) ? (
                textDateValidation
            ) : (
                <FormCapRates capRate={_capRate} prevDatesCap={_prevDatesCap} />
            );
        dialog.width = 1000;
        dialogService.open(dialog);
    };

    return (
        <div className="tab-content tabDiv p-3">
            <Row>
                <Row>
                    <TreeGridComponent
                        dataSource={dataSource}
                        columns={CapRatesColumns}
                        treeColumnIndex={0}
                        height="350"
                        childMapping="capSubRates"
                        rowSelected={({ data }) => setCapRateDetailRow(data)}
                        selectionSettings={{ mode: 'Row', type: 'Single' }}
                        ref={treeGridRef}
                        toolbar={['Create']}
                        editSettings={{ allowAdding: true }}
                        toolbarClick={toolbarClick}
                        allowSelection={true}
                        allowExcelExport={true}
                        showColumnChooser={true}
                    >
                        <InjectToolbar services={[Toolbar, ExcelExport]} />
                    </TreeGridComponent>
                </Row>
                <Row style={{ padding: '20px' }}>
                    {capRate?.sequence !== undefined && capRate?.sequence !== 0 ? (
                        <FormCapRates capRate={capRate} prevDatesCap={prevDatesCap} />
                    ) : null}
                </Row>
            </Row>
            <ToastComponent id="toast_target" ref={toastRef} title="" content="" position={{ X: 'Right', Y: 'Top' }} />
        </div>
    );
};

export default CapRatesTab;
