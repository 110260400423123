import { MemberEnrollmentApi } from 'components/AvaEzm/MemberEnrollment/MemberEnrollmentApi';
import { EnrollmentIntakeIds } from 'components/AvaEzm/MemberEnrollment/MemberEnrollmentDetails/MemberEnrollmentConfig';
import { ControlledSyncfusionTextBox } from 'components/Common/SyncfusionWrappers/Syncfusion';
import useToast from 'hooks/useToast';
import { useRef } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { dialogService } from 'subjects/common/DialogService';
import 'components/AvaEzm/MemberEnrollment/AdminAndSupport/AdminAndSupport.scss';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

const WebEnrollmentPortal = () => {
    const SendEnrollments = useRef<HTMLFormElement>(null);
    const loaderRef = useRef<HTMLDivElement>(null);
    const { showErrorToast, showSuccessToast } = useToast();

    const {
        handleSubmit,
        setValue,
        control,
        formState: { errors },
    } = useForm<EnrollmentIntakeIds | any>({ mode: 'all' });

    const onSubmit = (data, e) => {
        e.preventDefault();
        const btnSubmitValue = e.nativeEvent.submitter.name;

        if (btnSubmitValue == 'SendToQueue') {
            sendEnrollmentsToServiceBus(data);
        }
    };

    const onError = (errors, e) => console.error(errors, e);
    const showLoader = (show: boolean) => {
        if (loaderRef.current) {
            loaderRef.current.style.display = show ? 'block' : 'none';
        }
    };

    const sendEnrollmentsToServiceBus = async (data: EnrollmentIntakeIds) => {
        showLoader(true);
        dialogService.loading(true);

        await MemberEnrollmentApi.SendEnrollmentsToServiceBus(data)
            .then((res) => {
                showSuccessToast(`Send the enrollments (${data['enrollmentIds']}) to service bus successfully`);
                setValue('enrollmentIds', '');
            })
            .catch((e) => {
                console.error(e);
                showErrorToast(
                    `Failed to send the enrollment (${data['enrollmentIds']}) to service bus. Please try again later`,
                );
            })
            .finally(() => {
                showLoader(false);
                dialogService.loading(false);
            });
    };

    return (
        <>
            <form ref={SendEnrollments} onSubmit={handleSubmit(onSubmit, onError)}>
                <div className="assignEnrollmentsContainer">
                    <Row>
                        <Col className="enrollmentIdLabel">ENROLLMENT ID(s):</Col>
                        <Col md={9}>
                            <TooltipComponent content={`Input enrollment IDs separated by comma (,)`}>
                                <div>
                                    <ControlledSyncfusionTextBox
                                        name="enrollmentIds"
                                        control={control}
                                        rules={{
                                            required: 'Enrollment ID(s) is required',
                                        }}
                                    />
                                </div>
                                <Form.Text className="text-danger">{errors.enrollmentIds?.message}</Form.Text>
                            </TooltipComponent>
                        </Col>
                        <Col>
                            <input
                                type="submit"
                                className="btn btn-primary btn-flat customBtn"
                                style={{ color: '#fff' }}
                                name="SendToQueue"
                                value="SEND TO QUEUE"
                            />
                        </Col>
                    </Row>
                </div>
            </form>
        </>
    );
};

export default WebEnrollmentPortal;
