import React, { useEffect, useRef, useState } from 'react';
import { GridComponent, Inject, Page, Toolbar } from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-react-navigations';
import moment from 'moment';
import { requests } from 'common/axios';
import { DOFRContract, DOFRContractTemplate, IPADetail } from 'components/ProviderData/IpaAdministration/atoms/Models';
import { showConfirmation } from 'components/ProviderData/IpaAdministration/atoms/utils';
import {
    BASE_URI,
    dofrContractForm,
    dofrContractsColumns,
    dofrContractsToolbar,
    DOFRContractsToolbarIds,
} from 'components/ProviderData/IpaAdministration/organisms/DOFR/DOFRContract.metadata';
import DOFRContractForm from 'components/ProviderData/IpaAdministration/organisms/DOFR/DOFRContractForm';
import { dofrService } from 'components/ProviderData/subjects/IpaAdministrationService';
import useToast from 'hooks/useToast';
import useUnsubscribe from 'hooks/useUnsubscribe';
import { Dialog } from 'interfaces/Dialog';
import { dialogService } from 'subjects/common/DialogService';

const TOOLBAR_IDS = [DOFRContractsToolbarIds.New, DOFRContractsToolbarIds.Edit, DOFRContractsToolbarIds.Delete];

type DOFRContractsGridProps = {
    isReadOnlyViewer: boolean;
    ipa: IPADetail;
    templates: DOFRContractTemplate[];
    setLoading: (loading: boolean) => void;
    selectedContract: DOFRContract | null;
    setSelectedContract: (c: DOFRContract | null) => void;
    pendingSelectionId: string;
    setPendingSelectionId: (s: string) => void;
};
const DOFRContractsGrid = ({
    isReadOnlyViewer,
    templates,
    ipa,
    setLoading,
    selectedContract,
    setSelectedContract,
    pendingSelectionId,
    setPendingSelectionId,
}: DOFRContractsGridProps) => {
    const { effDate, endDate, ipaCode } = ipa;
    const gridRef = useRef<GridComponent | null>(null);
    const unselectableRef = useRef(false);
    const [contracts, setContracts] = useState<DOFRContract[]>([]);
    const { showErrorToast, showInfoToast, showSuccessToast } = useToast();
    const pushSubscription = useUnsubscribe();

    const fetchDOFRContracts = () => {
        gridRef.current?.showSpinner();
        requests
            .get<DOFRContract[]>(`${BASE_URI}/GetIpaDOFRContracts?ipaCode=${ipaCode}`)
            .then((data) => {
                data.sort(
                    (a, b) => new Date(b.effectiveBeginDate).getTime() - new Date(a.effectiveBeginDate).getTime(),
                );
                setContracts(data);
            })
            .catch((e) => {
                console.error(e);
                showErrorToast('Failed to fetch DOFR contracts. Please try again later');
            })
            .finally(() => gridRef.current?.hideSpinner());
    };

    const deleteDOFRContract = async () => {
        setLoading(true);
        requests
            .delete(`${BASE_URI}/${selectedContract?.id}`)
            .then(() => {
                dialogService.close();
                showSuccessToast('DOFR contract deleted');
                dofrService.refresh();
            })
            .catch((e) => {
                console.error(e);
                showErrorToast('Failed to delete DOFR contract. Please try again later');
            })
            .finally(() => setLoading(false));
    };

    const onToolbarClick = async (args: ClickEventArgs) => {
        switch (args.item.id) {
            case DOFRContractsToolbarIds.New:
            case DOFRContractsToolbarIds.Edit:
                getDateBoundaries(args.item.id);
                return showDOFRContractForm(args.item.id);
            case DOFRContractsToolbarIds.Close:
                return setSelectedContract(null);
            case DOFRContractsToolbarIds.Delete: {
                const shouldDelete = await showConfirmation({
                    message: 'Do you want to delete the DOFR Contract?',
                    title: 'Delete DOFR Contract',
                });
                if (shouldDelete) deleteDOFRContract();
            }
        }
    };

    const showDOFRContractForm = (actionId: DOFRContractsToolbarIds.New | DOFRContractsToolbarIds.Edit) => {
        const isAdd = actionId === DOFRContractsToolbarIds.New;
        if (!isAdd && !selectedContract) return showErrorToast('Please select a contract first');
        const dialog: Dialog = {};
        dialog.header = `${isReadOnlyViewer ? 'Viewing' : isAdd ? 'Adding' : 'Editing'} DOFR Contract`;
        dialog.buttons = isReadOnlyViewer
            ? null
            : dofrContractForm.getButtons({
                  onSubmitClick() {
                      dofrService.submit();
                  },
                  onCancelClick() {
                      dialogService.close();
                  },
              });
        dialog.content = () => (
            <DOFRContractForm
                isReadOnlyViewer={isReadOnlyViewer}
                isAdd={isAdd}
                contract={isAdd ? null : selectedContract}
                ipaCode={ipaCode}
                dateBoundaries={getDateBoundaries(actionId)}
                templates={templates}
                onSubmit={onFormSubmit}
            />
        );
        dialog.width = 900;
        dialogService.open(dialog);
    };

    const onFormSubmit = (data: DOFRContract | null) => {
        if (!data) {
            dialogService.close();
            return showInfoToast('There are no changes to be saved');
        }

        dialogService.loading(true);
        const promise = data.isAdd
            ? requests.post<DOFRContract>(`${BASE_URI}/Create`, { contractTemplateSourceId: data.id, data })
            : requests.put<DOFRContract>(`${BASE_URI}/${data.id}`, { data });
        promise
            .then((contract) => {
                setPendingSelectionId(contract.id);
                dialogService.close();
                showSuccessToast(`Contract ${data.isAdd ? 'created' : 'updated'}`);
                dofrService.refresh();
            })
            .catch((e) => {
                console.error(e);
                showErrorToast(`Failed to ${data.isAdd ? 'create' : 'update'} DOFR contract. Please try again later`);
            })
            .finally(() => dialogService.loading(false));
    };

    const getDateBoundaries = (actionId: string) => {
        if (actionId === DOFRContractsToolbarIds.New) {
            const latestContract = contracts?.at(0);
            return {
                min: latestContract
                    ? new Date(moment(latestContract.effectiveEndDate).add(1, 'days').toISOString())
                    : new Date(effDate),
                max: new Date(endDate ?? '12/31/9999'),
            };
        } else {
            const contractIndex = contracts.findIndex((c) => c.id === selectedContract?.id);
            if (contractIndex >= 0) {
                const prevContract = contractIndex + 1 <= contracts.length - 1 ? contracts[contractIndex + 1] : null;
                const nextContract = contractIndex - 1 >= 0 ? contracts[contractIndex - 1] : null;
                return {
                    min: prevContract
                        ? new Date(moment(prevContract.effectiveEndDate).add(1, 'days').toISOString())
                        : new Date(effDate),
                    max: nextContract
                        ? new Date(moment(nextContract.effectiveBeginDate).subtract(1, 'days').toISOString())
                        : new Date(endDate ?? '12/31/9999'),
                };
            }
            return { min: new Date(effDate ?? Date.now()), max: new Date(endDate ?? '12/31/9999') };
        }
    };

    useEffect(() => {
        const closeBtn = document.getElementById(DOFRContractsToolbarIds.Close);
        if (selectedContract) closeBtn && closeBtn.classList.remove('e-disabled');
        else closeBtn && closeBtn.classList.add('e-disabled');

        if (isReadOnlyViewer) gridRef.current?.toolbarModule?.enableItems(TOOLBAR_IDS, false);
        else {
            if (selectedContract) gridRef.current?.toolbarModule?.enableItems(TOOLBAR_IDS.slice(1), true);
            else gridRef.current?.toolbarModule?.enableItems(TOOLBAR_IDS.slice(1), false);
        }
    }, [selectedContract, isReadOnlyViewer]);

    useEffect(() => {
        fetchDOFRContracts();
        pushSubscription(
            dofrService.get$().subscribe(({ type }) => {
                if (type === 'refresh') {
                    setSelectedContract(null);
                    fetchDOFRContracts();
                }
            }),
        );
    }, []);

    return (
        <>
            <label className="gridTitle mb-1">DOFR Contracts</label>
            <GridComponent
                ref={gridRef}
                allowPaging
                columns={dofrContractsColumns}
                dataSource={contracts}
                dataBound={() => {
                    if (pendingSelectionId) {
                        const index = contracts.findIndex((c) => c.id === pendingSelectionId);
                        index >= 0 && gridRef.current?.selectRow(index);
                    }
                }}
                height={200}
                rowHeight={40}
                recordDoubleClick={() => showDOFRContractForm(DOFRContractsToolbarIds.Edit)}
                rowSelected={({ data }) => {
                    unselectableRef.current = false;
                    setPendingSelectionId('');
                    setSelectedContract(data);
                }}
                toolbar={dofrContractsToolbar}
                toolbarClick={onToolbarClick}
                rowDeselecting={(args) => {
                    if (!unselectableRef.current && selectedContract) args.cancel = true;
                }}
                rowSelecting={() => (unselectableRef.current = true)}
            >
                <Inject services={[Page, Toolbar]} />
            </GridComponent>
        </>
    );
};

export default DOFRContractsGrid;
