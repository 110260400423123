import React, { useRef, useState } from 'react';
import styles from './BoxSection.module.css';

const SectionHeader = (props) => {
    return (
        <div className={`gridHeaderDiv ${props.backgroundColor || ''} ${props.cssClass || ''}`} style={props.style}>
            {props.title && <label className="gridTitle">{props.title}</label>}
            {props.children}
        </div>
    );
};

const Box = (props) => {
    return (
        <div
            className={`box box-solid box-default ${props.addMargin ? 'section-margin' : ''} ${
                props.borderColor ? props.borderColor + '-border' : ''
            } ${props.cssClass || ''}`}
            style={props.style}
        >
            {props.children ?? ''}
        </div>
    );
};

const BoxSection = (props) => {
    const containerId = props.id ?? null;

    return (
        <div className={`grid-wrapper ${props.cssClass || ''}`} id={containerId} style={props.style}>
            <SectionHeader title={props.header ?? ''}>{props.headerChildren}</SectionHeader>
            {props.children}
        </div>
    );
};

const Section = (props) => {
    return <div className={`section ${props.cssClass || ''}`}>{props.children ?? ''}</div>;
};

const BoxSectionSlim = (props) => {
    return (
        <div className="box box-solid box-info">
            <div className="box-header secondary with-border box-info">
                <h4>{props.header ?? ''}</h4>
            </div>
            {props.children ?? ''}
        </div>
    );
};

const GridAlikeSection = ({ children, containerClassName = '', title = '', titleRight = '' }) => {
    return (
        <div className={`${styles['grid-alike-section']}${containerClassName ? ` ${containerClassName}` : ''}`}>
            <SectionHeader title={title}>
                <div className={styles['grid-header-right']}>{titleRight}</div>
            </SectionHeader>
            {children}
        </div>
    );
};

const CollapsableBoxSection = (props) => {
    const [isOpen, toggleOpen] = useState(props.startClosed ? false : true);
    const childrenRef = useRef(null);

    function getChildrenHeight() {
        return (childrenRef.current && childrenRef.current.getBoundingClientRect().height) || 0;
    }

    return (
        <div className={props.cssClass ?? ''} style={props.style}>
            <SectionHeader
                title={props.header}
                backgroundColor={props.dark ? 'dark' : 'light'}
                cssClass={props.headerCssClass || `blue-border ${props.dark ? 'no-top-border' : ''}`}
                style={props.headerStyle}
            >
                {props.headerChildren}
                <div style={{ marginLeft: 'auto', display: 'flex' }}>
                    {props.headerActions}
                    <button
                        type="button"
                        className="btn btn-box-tool box-header-button"
                        onClick={() => {
                            typeof props.changeGridHeight === 'function' &&
                                props.changeGridHeight((isOpen ? 1 : -1) * getChildrenHeight());
                            toggleOpen(!isOpen);
                        }}
                    >
                        {isOpen ? <i className="fa fa-minus" /> : <i className="fa fa-plus" />}
                    </button>
                </div>
            </SectionHeader>
            {isOpen && <Box cssClass="no-top-border no-shadow">{props.children ?? ''}</Box>}
        </div>
    );
};

export { Box, Section, BoxSection, BoxSectionSlim, CollapsableBoxSection, SectionHeader, GridAlikeSection };
