import React, { ReactNode, useCallback, useEffect, useState, createContext } from 'react';
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { isNil } from 'lodash';
import { Observable, Subscription, takeUntil } from 'rxjs';
import useUnmountSubject from 'hooks/useUnmountSubject';
import { createHubConnectionService, messageCMSubjectService } from 'subjects/CareManagement/CareManagementSubjects';
import { exportPdfMembersService } from 'subjects/common/ExportMembersService';
import { notificationService } from 'subjects/common/NotificationService';
import { TabLayoutService } from 'subjects/common/TabLayoutService';
import { tabService } from 'subjects/common/tabService';
import { primaryTheme, secondaryTheme } from './theme';

class MapTab {
    idPassed: string;
    idGenerated: number;
    freeTab: boolean;

    constructor(idPass: string, idGen: number, free: boolean) {
        this.idPassed = idPass;
        this.idGenerated = idGen;
        this.freeTab = free;
    }
}

let socketFillStore = [
    new MapTab('1', 1, false),
    new MapTab('1', 1, false),
    new MapTab('1', 1, false),
    new MapTab('1', 1, false),
    new MapTab('1', 1, false),
    new MapTab('1', 1, false),
];

export interface TabLayoutProps {
    theme: 'primary' | 'secondary';
    beforeCloseTab?: (e: React.MouseEvent, next: () => void, tab) => void;
    initialTabs?: TabProps[];
    controlledTabs?: TabProps[];
    newTab$?: Observable<TabProps>;
    selectedTab?: number;
    flagCMCases?: boolean;
    onChangeTab?: (tabIndex: number) => void;
    parentProps?: any;
}

export interface TabContentProps {
    openNewTab?: (tab: TabProps, openInBackground?: boolean) => void;
    closeTab?: () => void;
}

export interface TabProps {
    closable?: boolean;
    color?: any;
    content: typeof React.Component | React.FC;
    onSelect?: () => void;
    title: string;
    id?: string;
}

interface TabPanelProps {
    children?: ReactNode;
    index: number;
    topBorder: boolean;
    value: number;
}

const TabPanel = ({ index, value, children, topBorder }: TabPanelProps) => {
    // used for lazy loading the child
    const [dirty, setDirty] = useState<boolean>(false);

    useEffect(() => {
        if (value === index) {
            setDirty(true);
        }
    }, [value, index]);

    return (
        <Box hidden={value !== index} style={topBorder ? { borderTop: 'solid 4px #00b3e3' } : undefined}>
            {dirty ? children : null}
        </Box>
    );
};

const TabLayout = ({
    initialTabs,
    theme,
    newTab$,
    controlledTabs,
    beforeCloseTab,
    selectedTab,
    flagCMCases,
    onChangeTab,
    parentProps,
}: TabLayoutProps) => {
    const [tabs, setTabs] = useState(controlledTabs ?? initialTabs ?? []);
    const [selectedTabIndex, setSelectedTabIndex] = useState(selectedTab || 0);
    const [exportPdfState, setExportPdfState] = useState<string[][]>([]);

    const openNewTab = useCallback(
        (tab: TabProps, openInBackground?: boolean) => {
            const existingTabIndex = tab.id ? tabs.map((t) => t.id).indexOf(tab.id) : -1;
            if (existingTabIndex === -1) {
                const newtabindex = tabs.length;
                setTabs([...tabs, tab]);
                if (flagCMCases && tab.id) {
                    createHubConnectionService.invokeSaveConnection(tab.id, newtabindex);
                }
                if (!openInBackground) {
                    setSelectedTabIndex(newtabindex);
                }
            } else {
                setSelectedTabIndex(existingTabIndex);
            }
        },
        [tabs],
    );

    const setTabColor = useCallback(
        (color, index) => {
            const updatedTabs = [...tabs];
            updatedTabs[index].color = color;
            setTabs(updatedTabs);
        },
        [tabs],
    );

    const selectTab = useCallback(
        (tabId: string) => {
            const tabIndex = tabs.findIndex((t) => t.id === tabId);
            if (tabIndex > -1) {
                setSelectedTabIndex(tabIndex);
            }
        },
        [tabs],
    );

    const getTabIndex = useCallback(
        (tabId: number | string) => {
            for (let i = 0; i < tabs.length; i++) {
                const tab = tabs[i];
                if (tab.id === tabId) {
                    return i;
                }
            }
        },
        [tabs],
    );

    const closeTab = (i: number, e?: React.MouseEvent) => {
        if (selectedTabIndex >= i) {
            setSelectedTabIndex(selectedTabIndex - 1);
        }
        setTabs(tabs.filter((t, idx) => i !== idx));
        e && e.stopPropagation();
        if (flagCMCases) {
            if (socketFillStore[i].freeTab) {
                createHubConnectionService.invokeFreeConnection(socketFillStore[i].idPassed);
            }
            socketFillStore = socketFillStore.filter((_item: MapTab, indx: number) => i !== indx);
        }
    };

    const onCloseTab = (e: React.MouseEvent, i: number, tab) => {
        if (beforeCloseTab) {
            beforeCloseTab(
                e,
                () => {
                    closeTab(i, e);
                },
                tab,
            );
        } else {
            closeTab(i, e);
        }
    };
    useEffect(() => {
        const subscriptions: Subscription[] = [];
        if (newTab$) {
            subscriptions.push(newTab$.subscribe(openNewTab));
        }
        return () => subscriptions.forEach((s) => s.unsubscribe());
    }, [newTab$, openNewTab]);

    useEffect(() => {
        if (!isNil(selectedTab) && selectedTab !== selectedTabIndex) {
            setSelectedTabIndex(selectedTab);
        }
    }, [selectedTab]);

    useEffect(() => {
        if (controlledTabs) {
            setTabs(controlledTabs);
        }
    }, [controlledTabs]);

    useEffect(() => {
        onChangeTab && onChangeTab(selectedTabIndex);
        if (onChangeTab) {
            exportPdfMembersService.select(exportPdfState[selectedTabIndex] || []);
        }
        const subscription = tabService
            .get$()
            .subscribe((tabId: any) => closeTab(getTabIndex(tabId) || selectedTabIndex));
        return () => subscription.unsubscribe();
    }, [selectedTabIndex]);

    useEffect(() => {
        let messageSubscriptionCMHub: Subscription;
        if (flagCMCases) {
            messageSubscriptionCMHub = messageCMSubjectService.get$().subscribe((object: any) => {
                if (object.status) {
                    socketFillStore.push(new MapTab(object.member, object.tabId, object.status));
                } else {
                    notificationService.warning({
                        title: 'Occupied',
                        content: object.message,
                        messageLevel: 1,
                    });
                    socketFillStore.push(new MapTab(object.member, object.tabId, object.status));
                }
            });
        }
        return () => {
            if (flagCMCases) {
                messageSubscriptionCMHub?.unsubscribe();
                socketFillStore = [
                    new MapTab('1', 1, false),
                    new MapTab('1', 1, false),
                    new MapTab('1', 1, false),
                    new MapTab('1', 1, false),
                    new MapTab('1', 1, false),
                    new MapTab('1', 1, false),
                ];
            }
        };
    }, []);

    const unmountSubscriptions$ = useUnmountSubject();
    useEffect(() => {
        TabLayoutService.tabChanges$().pipe(takeUntil(unmountSubscriptions$)).subscribe(selectTab);
    }, []);

    const getTabColorStyle = (
        theme: string | undefined,
        tabColor: string | undefined,
        tabIndex: number,
        selectedTabIndex: number,
    ) => {
        if (theme === 'secondary') {
            return undefined;
        }

        const cssTabColor = tabColor === 'gray' ? '#808080' : tabColor === 'blue' ? '#00b3e3' : tabColor;

        if (tabIndex === selectedTabIndex) {
            return cssTabColor && cssTabColor !== '#00b3e3'
                ? {
                      backgroundColor: cssTabColor,
                      color: '#fff',
                      borderColor: cssTabColor,
                  }
                : undefined; // fallback to default theme color
        } else {
            return cssTabColor === '#808080'
                ? {
                      backgroundColor: '#fff',
                      color: '#808080',
                      borderColor: '#808080',
                  }
                : {
                      backgroundColor: '#ffffff',
                      color: '#00b3e3',
                      borderColor: '#00b3e3',
                  };
        }
    };

    return (
        <ThemeProvider theme={theme === 'secondary' ? secondaryTheme : primaryTheme}>
            <Tabs
                variant={theme === 'secondary' ? 'fullWidth' : 'scrollable'}
                value={selectedTabIndex}
                onChange={(e, newValue) => {
                    setSelectedTabIndex(newValue);
                    exportPdfMembersService.select(exportPdfState[newValue] || []);
                    const selectedTab = tabs[newValue];
                    if (selectedTab && selectedTab.onSelect) {
                        selectedTab.onSelect();
                    }
                }}
            >
                {tabs.map((tab, i) => (
                    <Tab
                        key={`th_${tab.id ?? i}`}
                        label={tab.title}
                        icon={
                            tab.closable ? (
                                <div
                                    onClick={(e) => {
                                        onCloseTab(e, i, tab);
                                    }}
                                >
                                    <span className="e-icons e-close-icon" style={{ fontSize: 10 }} />
                                </div>
                            ) : undefined
                        }
                        iconPosition="end"
                        style={getTabColorStyle(theme, tab.color, i, selectedTabIndex)}
                    />
                ))}
            </Tabs>
            {tabs.map((tab, tabIndex) => (
                <TabContext.Provider
                    key={`tctx_${tab.id ?? tabIndex}`}
                    value={{
                        tabIndex,
                        selectedTabIndex,
                        openNewTab,
                        closeTab,
                        setTabColor,
                        setMemberPdfExportList: (memberIds: string[]) => {
                            exportPdfState[tabIndex] = memberIds;
                            exportPdfMembersService.select(memberIds);
                            setExportPdfState(exportPdfState);
                        },
                    }}
                >
                    <TabPanel
                        key={`tp_${tab.id ?? tabIndex}`}
                        index={tabIndex}
                        value={selectedTabIndex}
                        topBorder={theme === 'primary' && (!tab.color || tab.color === 'blue')}
                    >
                        <tab.content key={`tc_${tab.id ?? tabIndex}`} parentProps={parentProps} />
                    </TabPanel>
                </TabContext.Provider>
            ))}
        </ThemeProvider>
    );
};

export type TabCallbacks = {
    tabIndex: number;
    selectedTabIndex: number;
    openNewTab: (tab: TabProps, openInBackground?: boolean) => void;
    closeTab: (index: number) => void;
    setTabColor: (color: any, index: number) => void;
    setMemberPdfExportList: (memberIds: string[]) => void;
};

export const TabContext = createContext<TabCallbacks | null>(null);

export default React.memo(TabLayout);
