import { useEffect, useState } from 'react';
import { Observable, Observer, Subject, takeUntil } from 'rxjs';

function useUnmountSubject() {
    const [unmountSubject] = useState(new Subject<void>());
    useEffect(() => {
        return () => {
            unmountSubject.next();
            unmountSubject.complete();
        };
    }, []);

    return unmountSubject;
}

export function useUnmount() {
    const [unmountSubject] = useState(new Subject<void>());

    useEffect(() => {
        return () => {
            unmountSubject.next();
            unmountSubject.complete();
        };
    }, []);

    return function boilerplate<T>(
        observable: Observable<T>,
        observerOrNext?: Partial<Observer<T>> | ((value: T) => void),
        onError?: (err: any) => void,
    ) {
        const newObserver = (f: (value: T) => void) => ({ next: f, error: onError });
        const partialObserverOrUndefined =
            typeof observerOrNext === 'function'
                ? newObserver(observerOrNext)
                : observerOrNext; // (already an observer) or undefined;
        const pipedObservable = observable.pipe(takeUntil(unmountSubject)); // do this here to avoid doing it everywhere
        return partialObserverOrUndefined
            ? pipedObservable.subscribe(partialObserverOrUndefined)
            : pipedObservable.subscribe(); // its undefined, caller doesn't need an observer (rare use case from a POST's void response)
    };
}

export default useUnmountSubject;
