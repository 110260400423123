import React, { useEffect, useRef, useState } from 'react';
import { ToastComponent } from '@syncfusion/ej2-react-notifications';
import { TreeGridComponent, Inject as InjectToolbar, Toolbar, ExcelExport, Sort } from '@syncfusion/ej2-react-treegrid';
import moment from 'moment';
import { Row } from 'react-bootstrap';
import { requests } from 'components/AvaEzm/AxiosApi';
import { textDateValidation } from 'components/ProviderData/IpaAdministration/atoms/IpaAdministration.Metadata';
import { GainShareRateDetail, IPADetail } from 'components/ProviderData/IpaAdministration/atoms/Models';
import ChangeHistoryGrid from 'components/ProviderData/IpaAdministration/organisms/GainShareRates/ChangeHistoryGrid';
import { GainShareRateForm } from 'components/ProviderData/IpaAdministration/organisms/GainShareRates/GainShareRateForm';
import { gainShareRatesColumns } from 'components/ProviderData/IpaAdministration/organisms/GainShareRates/GainShareRates.Metadata';
import { gainShareRateService } from 'components/ProviderData/subjects/IpaAdministrationService';
import useUnsubscribe from 'hooks/useUnsubscribe';
import { Dialog } from 'interfaces/Dialog';
import { dialogService } from 'subjects/common/DialogService';
import GainShareTieredRateForm from './GainShareTieredRateForm';

type GainShareRatesTabProps = {
    ipaCode: string;
    ipaId?: number;
    ipa: IPADetail;
    isReadOnlyViewer: boolean;
};

const GainShareRatesTab = (props: GainShareRatesTabProps) => {
    const { ipaCode, ipa, isReadOnlyViewer } = props;
    const { effDate, endDate, companyCode } = ipa;
    const toastRef = useRef<ToastComponent>(null);
    const treeGridRef = useRef<TreeGridComponent>(null);
    const pushSubscription = useUnsubscribe();
    const [pendingSelection, setPendingSelection] = useState<any>(null);
    const [dataSource, setDataSource] = useState<GainShareRateDetail[]>([]);
    const [capRevenues, setCapRevenues] = useState<any[]>([]);
    const [nextSequence, setNextSequence] = useState(1);
    const [gainShareRateDetail, setGainShareRateDetail] = useState<GainShareRateDetail>({} as GainShareRateDetail);

    const [prevDatesGain, setPrevDatesGain] = useState<
        { effectiveStartDate: Date | string; effectiveEndDate: Date | string }[] | null
    >(null);
    const [maxEndDate, setMaxEndDate] = useState<Date>();
    const formatData = (d, parentRate?: any) => ({
        ...d,
        sequence: d.memberCountStart ? `Range (${d.memberCountStart} - ${d.memberCountEnd})` : d.sequence,
        parentRateId: parentRate?.rateId,
    });
    const updateDataSource = async () => {
        const searchUrlGainShareRates = `/api/IpaGainShareRate/GetListOfGainShareRates?IpaCode=${ipaCode}`;
        const data = await requests.get<any>(searchUrlGainShareRates);

        const formatedData = data
            .map(formatData)
            .filter((d) => d.isDeleted == false)
            .sort((x, y) => y.sequence - x.sequence)
            .map((d) => ({
                ...d,
                gainShareTieredRates: d.gainShareTieredRates
                    ?.filter((tieredRate) => tieredRate.isDeleted == false)
                    .map(formatData)
                    .sort((a, b) => b.rateId - a.rateId),
            }));

        const nextSeq =
            data && data.length > 0
                ? Math.max.apply(
                      null,
                      data.map((d) => d.sequence),
                  )
                : 0;

        const maxEffEndDate = new Date(
            Math.max(
                ...formatedData.map((d) => {
                    return new Date(d.effectiveEndDate);
                }),
            ),
        );

        setNextSequence(nextSeq + 1);
        setDataSource(formatedData);
        setMaxEndDate(maxEffEndDate);
    };

    const setPreviousGainDates = (row) => {
        const previousGainShareRate = dataSource.filter((d) => d.id !== row.id);
        let extractDates = {} as GainShareRateDetail[];
        if (row.parentItem) {
            extractDates = [row.parentItem];
        } else if (previousGainShareRate) {
            extractDates = previousGainShareRate;
        }
        setPrevDatesGain(extractDates);
        return extractDates;
    };

    const setGainShareRateDetailRow = (row: any) => {
        let gainShareData: any;
        if (row?.parentItem) {
            row.parentRateId = row.parentItem.rateId;
        }
        const parentRateId = row?.parentItem?.rateId ?? row.rateId;
        const selectedParentRate = dataSource.find((d) => d.rateId === parentRateId);

        dataSource?.forEach((p: GainShareRateDetail) => {
            if (p.id === row.id) gainShareData = p;
            if (gainShareData) return;
            p.gainShareTieredRates?.forEach((c) => {
                if (c.id === row.id) {
                    gainShareData = c;
                    return;
                }
            });
        });

        setGainShareRateDetail({
            ...gainShareData,
            id: row.id,
            sequence: row.sequence,
            parentRateId: row?.parentItem?.rateId,
            isGainShareRate: row?.schemaName ? true : false,
            effectiveStartDate: row.effectiveStartDate,
            effectiveEndDate: row.effectiveEndDate,
            parentRateData: selectedParentRate,
        } as GainShareRateDetail);
        setPreviousGainDates(row);
    };

    const FormGainShareRates = ({ gainShareRate, prevDatesGain }) => {
        const _capRevenues = capRevenues.length <= 0 ? gainShareRate.capRevenues : capRevenues;
        const _sequence = gainShareRate?.isCopy ? gainShareRate?.sequence : nextSequence;
        return gainShareRate.isGainShareRate ? (
            <GainShareRateForm
                nextSequence={_sequence}
                ipaCode={ipaCode}
                capRevenues={_capRevenues}
                gainShareRate={gainShareRate}
                ipaInfo={{ dates: { effDate, endDate } }}
                prevDatesGain={prevDatesGain}
                maxEndDate={maxEndDate}
                isReadOnlyViewer={isReadOnlyViewer}
                setPendingSelection={setPendingSelection}
            />
        ) : (
            <GainShareTieredRateForm
                data={gainShareRate}
                isReadOnlyViewer={isReadOnlyViewer}
                setPendingSelection={setPendingSelection}
            />
        );
    };

    const toolbarClick = ({ item }) => {
        if (item.text === 'Create') {
            gainShareRateService.add();
            const gainShareRateDate = {
                sequence: nextSequence,
                ipaCode: ipaCode,
                rateId: 0,
                partitionKey: ipaCode,
                schemaName: 'IpaGainShare',
                schemaVersion: '1.0',
                createdAt: new Date(),
                isGainShareRate: true,
            } as any;

            setGainShareRateDetail({ ...gainShareRateDate, sequence: 0 });
            showCreateDialog(gainShareRateDate, false);
        } else {
            const columnsToRemove = ['sequence', 'active', 'effectiveStartDatef', 'effectiveEndDatef'];
            const columnsToExport = gainShareRatesColumns.filter((c) => !columnsToRemove.includes(c.field)) as any[];
            treeGridRef.current?.excelExport({
                includeHiddenColumn: true,
                fileName: 'GainShareRates-report.xlsx',
                columns: columnsToExport,
                header: {
                    headerRows: 1,
                    rows: [
                        {
                            cells: [
                                {
                                    colSpan: 12,
                                    value: 'Cap Rates Report',
                                    style: {
                                        fontColor: '#ffffff',
                                        backColor: '#00b3e3',
                                        fontSize: 25,
                                        hAlign: 'Center',
                                        bold: true,
                                    },
                                },
                            ],
                        },
                    ],
                },
            });
        }
    };

    const showCreateDialog = (_GainShareRate, isGainShareRateCreation) => {
        let _prevDatesGain = setPreviousGainDates({
            sequenceNumber: Infinity,
            isGainShareRate: _GainShareRate.isGainShareRate,
        });
        if (isGainShareRateCreation) {
            const { effectiveStartDate, effectiveEndDate } = _GainShareRate;
            _prevDatesGain = [{ effectiveStartDate, effectiveEndDate }] as GainShareRateDetail[];
        }
        const dialog: any = {};
        if (_GainShareRate.isGainShareRate) {
            dialog.header = _GainShareRate.isCopy ? 'Copy Gain Share Rate' : 'Create Gain Share Rate';
        } else {
            dialog.header = 'Create Gain Share Tiered Rate';
        }

        const ipaEndDate = moment(ipa.endDate).startOf('day');
        const PrevEndDate = moment(_prevDatesGain.find((f) => f)?.effectiveEndDate).startOf('day');
        const gainShareRateEffDate = moment(_GainShareRate?.maxEndDate).startOf('day');
        dialog.buttons = [];

        dialog.content = () =>
            (ipaEndDate.isSame(PrevEndDate) && _GainShareRate.isGainShareRate) ||
            (ipaEndDate.isSame(gainShareRateEffDate) && _GainShareRate?.isCopy) ? (
                textDateValidation
            ) : (
                <FormGainShareRates gainShareRate={_GainShareRate} prevDatesGain={_prevDatesGain} />
            );
        dialog.width = 1000;
        dialogService.open(dialog);
    };

    const fetchCapRevenuesValues = async (companyCode: string, type: string) => {
        let _capRevenues = await requests.get<any[]>(
            `/api/CapRate/GetCapRevenueFormulas?companyCode=${companyCode}&type=${type}`,
        );
        _capRevenues = _capRevenues.map((c) => ({ ...c, text: `${c.code} - ${c.description}` }));
        if (type === 'CMS') {
            setCapRevenues(_capRevenues);
        }
    };

    const showChangeHistoryDialog = (gainShareData) => {
        const dialog: Dialog = {};
        dialog.header = `Gain Share ${gainShareData.isGainShareRate ? '' : 'Tiered '}Rate - Change History`;
        dialog.width = 1000;
        dialog.content = () => (
            <ChangeHistoryGrid
                isReadOnlyViewer={isReadOnlyViewer}
                gainShareData={gainShareData}
                setPendingSelection={setPendingSelection}
                approvalCallback={() => {
                    updateDataSource();
                    setGainShareRateDetail({} as GainShareRateDetail);
                }}
            />
        );
        dialogService.open(dialog);
    };

    useEffect(() => {
        fetchCapRevenuesValues(companyCode, 'CMS');
        updateDataSource();
        if (treeGridRef && isReadOnlyViewer) {
            // Disable toolbar items.
            treeGridRef.current?.toolbarModule.enableItems(
                [treeGridRef.current.element.id + '_gridcontrol_Create'],
                false,
            );
        }

        pushSubscription(
            gainShareRateService.get$().subscribe(({ type, gainShareRateData }) => {
                if (type === 'submit') {
                    const typesText = {
                        submit: 'Saved',
                    };
                    toastRef.current?.show({
                        title: 'Success !',
                        content: `Gain Share Rate ${typesText[type]} Successfully`,
                        cssClass: 'e-toast-success',
                    });
                    updateDataSource();
                    setGainShareRateDetail({} as GainShareRateDetail);
                } else if (type === 'cancel') {
                    setGainShareRateDetail({} as GainShareRateDetail);
                    dialogService.close();
                } else if (type === 'addGainShareTieredRate' || type === 'copy') {
                    const _gainShareRateData = {
                        ...gainShareRateData,
                        isCopy: type === 'copy',
                        gainShareTieredRates: [],
                    } as GainShareRateDetail;

                    setGainShareRateDetail({ ..._gainShareRateData, sequence: 0 });
                    showCreateDialog(_gainShareRateData, true);
                } else if (type === 'viewHistory') {
                    showChangeHistoryDialog(gainShareRateData);
                }
            }),
        );
    }, [companyCode]);

    return (
        <div className="tab-content tabDiv p-3">
            <Row>
                <Row>
                    <TreeGridComponent
                        dataSource={dataSource}
                        dataBound={() => treeGridRef.current?.expandAll()}
                        expanded={() => {
                            if (pendingSelection) {
                                const visibleRecords = treeGridRef.current?.getVisibleRecords();
                                const index = visibleRecords?.findIndex((r: any) => r.id === pendingSelection.id);
                                typeof index === 'number' && treeGridRef.current?.selectRow(index);
                            }
                        }}
                        columns={gainShareRatesColumns}
                        treeColumnIndex={0}
                        height="350"
                        childMapping="gainShareTieredRates"
                        rowSelected={({ data }) => setGainShareRateDetailRow(data)}
                        selectionSettings={{ mode: 'Row', type: 'Single' }}
                        ref={treeGridRef}
                        toolbar={['Create']}
                        editSettings={{ allowAdding: true }}
                        toolbarClick={toolbarClick}
                        allowSelection={true}
                        allowExcelExport={true}
                        showColumnChooser={true}
                        allowSorting={true}
                    >
                        <InjectToolbar services={[Toolbar, Sort, ExcelExport]} />
                    </TreeGridComponent>
                </Row>
                <Row style={{ padding: '20px' }}>
                    {gainShareRateDetail?.sequence !== undefined && gainShareRateDetail?.sequence !== 0 ? (
                        <FormGainShareRates gainShareRate={gainShareRateDetail} prevDatesGain={prevDatesGain} />
                    ) : null}
                </Row>
            </Row>
            <ToastComponent id="toast_target" ref={toastRef} title="" content="" position={{ X: 'Right', Y: 'Top' }} />
        </div>
    );
};

export default GainShareRatesTab;
