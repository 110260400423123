import React from 'react';
import { ToastComponent } from '@syncfusion/ej2-react-notifications';
import { notificationService, MESSAGELEVEL } from '../../subjects/common/NotificationService';
import { isNil } from 'lodash';

export default class Notification extends React.Component {
    componentDidMount() {
        this.subscription = notificationService.get().subscribe({
            next: ({ title, content, messageLevel, cssClass, duration, showCloseButton }) => {
                this.toastInstance.title = title;
                this.toastInstance.content = content;
                this.toastInstance.showCloseButton = showCloseButton;
                switch (messageLevel) {
                    case MESSAGELEVEL.SUCCESS:
                        this.toastInstance.cssClass = 'e-toast-success';
                        break;
                    case MESSAGELEVEL.WARN:
                        this.toastInstance.cssClass = 'e-toast-warning';
                        break;
                    case MESSAGELEVEL.ERROR:
                        this.toastInstance.cssClass = 'e-toast-danger';
                        break;
                    case MESSAGELEVEL.CUSTOM:
                        this.toastInstance.cssClass = cssClass;
                        break;
                    default:
                        break;
                }
                this.toastInstance.show({ timeOut: isNil(duration) ? 5000 : duration });
            },
        });
    }

    componentWillUnmount() {
        this.subscription.unsubscribe();
    }

    render() {
        return <ToastComponent ref={(toast) => (this.toastInstance = toast)} position={{ X: '80%', Y: '10%' }} />;
    }
}
