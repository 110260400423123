import React, { useCallback, useEffect, useState } from 'react';
import '@syncfusion/ej2-react-inplace-editor/styles/inplace-editor/material.css';
import { Query } from '@syncfusion/ej2-data';
import { ButtonComponent, CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DatePickerComponent, MaskedDateTime, Inject } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent, FilteringEventArgs } from '@syncfusion/ej2-react-dropdowns';
import { MaskedTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { SortOrder, TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import { AxiosError } from 'axios';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { requests } from 'common/axios';
import { tabDefaultSettings } from 'common/syncfusionConfigurations';
import { formatISODateTime } from 'common/widgets';
import { getItems } from 'components/AvaEzm/Utilities/ODataUtility';
import { IPA, PointOfContact, VendorInfo } from 'components/ProviderData/IpaAdministration/atoms/Models';
import AdditionalContactTab from 'components/ProviderData/IpaAdministration/organisms/AdditionalContactTab';
import CapRatesTab from 'components/ProviderData/IpaAdministration/organisms/CapRates/CapRatesTab';
import DOFRContract from 'components/ProviderData/IpaAdministration/organisms/DOFR/DOFRContractTab';
import GainShareRatesTab from 'components/ProviderData/IpaAdministration/organisms/GainShareRates/GainShareRatesTab';
import { CreateIpaMember } from 'components/ProviderData/IpaAdministration/organisms/IpaAdministrationWizard';
import IpaCountyMappingTab from 'components/ProviderData/IpaAdministration/organisms/CountyMapping/IpaCountyMappingTab';
import IpaPlanMappingTab from 'components/ProviderData/IpaAdministration/organisms/PlanMapping/IpaPlanMappingTab';
import RiskPoolRatesTab from 'components/ProviderData/IpaAdministration/organisms/RiskPoolRates/RiskPoolRatesTab';
import { refreshTabService } from 'components/ProviderData/subjects/IpaAdministrationService';
import DisabledWrapper from 'components/ProviderData/utils/DisabledWrapper';
import useToast from 'hooks/useToast';
import useUserGroups from 'hooks/useUserGroups';
import { tabService } from 'subjects/common/tabService';
import 'components/ProviderData/IpaAdministration/organisms/IpaAdministration.css';
import IpaHospitalTab from 'components/ProviderData/IpaAdministration/organisms/IpaHospital/IpaHospitalTab';
import IpaNetworkMappingTab from 'components/ProviderData/IpaAdministration/organisms/IpaNetworkMapping/IpaNetworkMappingTab';

type IpaAdministrationDetailViewProps = {
    tabId: number;
    ipaId: number;
    ipaCode: string;
    children?: React.ReactNode;
};

function IpaAdministrationDetailView(props: IpaAdministrationDetailViewProps) {
    const { ipaId, ipaCode } = props;
    const [ipa, setIpa] = useState<any>({});
    const [companyCodes, setCompanyCodes] = useState<string[]>([]);
    const [mso, setMso] = useState<any>({});
    const [contracts, setContracts] = useState<any[]>([]);
    const [vendors, setVendors] = useState<any[]>([]);
    const [risks, setRisks] = useState<any[]>([]);
    const [types, setTypes] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>();
    const [termed, setTermed] = useState<string>();
    const [pointOfContact, setPointContact] = useState<PointOfContact>();
    const { showSuccessToast, showErrorToast } = useToast();
    const [editFlag, setEditFlag] = useState(false);
    const [dofrFlag, setDofrFlag] = useState(false);
    const [countyFlag, setCountyFlag] = useState(false);
    const [riskFlag, setRiskFlag] = useState(false);
    const [gainShareFlag, setGainShareFlag] = useState(false);
    const [hospitalFlag, setHospitalFlag] = useState(false);
    const [networkMappingFlag, setNetworkMappingFlag] = useState(false);
    const [isReadOnlyViewer, setIsReadOnlyViewer] = useState(false);
    const { belongToGroupName } = useUserGroups();

    const {
        register: contractInfoForm,
        handleSubmit: onClickContractInfo,
        setValue: setContractInfoValue,
        getValues: getContractInfoValues,
        formState: { errors, isDirty },
    } = useForm<IPA>();
    const {
        register,
        handleSubmit,
        setValue: setValuePointOfContact,
        getValues: getValuesPointOfContact,
    } = useForm<any>();
    const fields: object = { text: 'vendorId', value: 'vendorId' };
    let query: Query = new Query().select(['vendorId', 'vendorId']).take(50);
    const sortOrder: SortOrder = 'Ascending';

    const fetchTabFeatureFlags = async () => {
        const flags: any = await getItems<any>('/api/MedicalGroup/GetNetkworkAdminFeatureFlags');
        const _dofrFlag = flags.find((f) => f.name === 'enable-dofr-tab');
        const _countyFlag = flags.find((f) => f.name === 'enable-county-mapping-tab');
        const _riskPoolFlag = flags.find((f) => f.name === 'enable-risk-pool-tab');
        const _gainShareFlag = flags.find((f) => f.name === 'enable-gain-share-tab');
        const _hospitalFlag = flags.find((f) => f.name === 'enable-hospital-tab');
        const _networkMappingFlag = flags.find((f) => f.name === 'enable-network-mapping-tab');
        setDofrFlag(_dofrFlag?.isActive);
        setCountyFlag(_countyFlag?.isActive);
        setRiskFlag(_riskPoolFlag?.isActive);
        setGainShareFlag(_gainShareFlag?.isActive);
        setHospitalFlag(_hospitalFlag?.isActive);
        setNetworkMappingFlag(_networkMappingFlag?.isActive);
    };

    const fetchCompanyCodes = async () => {
        const _companyCodes = await getItems<string>('/api/MedicalGroup/GetCompanyCodes');
        setCompanyCodes(_companyCodes);
    };

    const fetchMso = async (contractId) => {
        let _mso: any = {};
        _mso = await getItems<string>(`/api/MedicalGroup/GetMsoDetails?contractId=${contractId}`);
        setMso(_mso);
        for (const m in _mso) {
            const field = m.charAt(0).toUpperCase() + m.slice(1);
            setContractInfoValue(('mso' + field) as keyof IPA, _mso[field]);
        }
        setTermed(_mso.isTermed);
    };

    const fetchContracts = async (_companyCode) => {
        let _contracts: any = [];
        _contracts = await getItems<string>(
            `/api/MedicalGroup/GetIpaContractsByCompanyCode?companyCode=${_companyCode}`,
        );
        setContracts(_contracts);
    };

    const fetchVendors = async () => {
        let _vendors: any = [];
        _vendors = await getItems<any>(`/api/MedicalGroup/GetVendorID/`);
        setVendors(_vendors);
    };

    const fetchRisks = async () => {
        let _risks: any = [];
        _risks = await getItems<string>(`/api/MedicalGroup/GetIpaRisks`);
        setRisks(_risks);
    };

    const fetchTypes = async () => {
        let _types: any = [];
        _types = await getItems<string>(`/api/MedicalGroup/GetIpaTypes`);
        setTypes(_types);
    };

    const fetchPointOfContact = async () => {
        const _poic = await requests.get<PointOfContact[]>(
            `/api/AdditionalContact/GetPointOfContact?ipaWebId=${ipaId}`,
        );
        const poic: any = _poic.find((p) => p);
        if (poic) {
            setPointContact(poic);
            for (const p in getValuesPointOfContact()) {
                setValuePointOfContact(p, poic[p]);
            }
        }
    };
    const savePointOfContact = async (data: PointOfContact) => {
        try {
            const url = '/api/AdditionalContact/AddOrUpdatePointOfContact';
            const adaptedData = {
                ...data,
            };
            if (ipaId && ipa.id) {
                adaptedData.IpaWebId = ipaId;
                adaptedData.manager = pointOfContact?.manager ? pointOfContact?.manager : '';
                adaptedData.specialist = pointOfContact?.specialist ? pointOfContact?.specialist : '';
                await requests.post(url, adaptedData);
            }
        } catch (e) {
            const message = `${(e as AxiosError)?.response?.data}`;
            showErrorToast(message);
        }
    };

    if (ipa?.vendorId) {
        const filteredVendors = new Query()
            .where('vendorId', 'startswith', ipa?.vendorId.slice(0, ipa?.vendorId.length / 2), true)
            .select(['vendorId', 'vendorId'])
            .take(50);
        if (filteredVendors) {
            query = filteredVendors;
        }
    }

    function onFiltering(e: FilteringEventArgs) {
        if (e.text === '') {
            e.updateData(vendors);
        } else {
            if (e.text.length < 1) {
                return;
            }
            let query: Query = new Query().select(['vendorId', 'vendorId']);
            query = e.text !== '' ? query.where('vendorId', 'startswith', e.text, true) : query;
            e.updateData(vendors, query);
        }
    }

    useEffect(() => {
        const controller = new AbortController();
        setLoading(true);
        Promise.all([
            fetchVendors(),
            fetchCompanyCodes(),
            fetchRisks(),
            fetchTypes(),
            ipaId ? fetchPointOfContact() : null,
            fetchTabFeatureFlags(),
        ]).finally(() => {
            setLoading(false);
        });

        return () => {
            controller.abort();
        };
    }, []);

    useEffect(() => {
        if (belongToGroupName('Provider Data Viewers') && !belongToGroupName('Provider Network Manager'))
            setIsReadOnlyViewer(true);
    }, [belongToGroupName]);

    useEffect(() => {
        const controller = new AbortController();
        if (companyCodes.length && risks.length && types.length) {
            if (ipaId) {
                requests.get(`/api/MedicalGroup/GetDetailByIpaId?ipaId=${ipaId}`).then((_ipa: any) => {
                    if (_ipa) {
                        setIpa(_ipa);
                        for (const field in _ipa) {
                            setContractInfoValue(field as keyof IPA, _ipa[field]);
                        }
                        setContractInfoValue('npi', _ipa.vendorNpi);
                        setContractInfoValue('effDate', _ipa.effDate);
                        setContractInfoValue('endDate', _ipa.endDate);
                        fetchVendorInfo(_ipa.vendorId, false);
                        fetchContracts(_ipa.companyCode);
                        if (_ipa.contractLevelId) {
                            fetchMso(_ipa.contractLevelId);
                        }
                        setLoading(false);
                    }
                });
            } else {
                setLoading(false);
            }
        }
        return () => {
            controller.abort();
        };
    }, [companyCodes, risks, types, ipaId]);

    const saveContractInformation = async (data: IPA) => {
        //if (!data.npi) {
        //    showSuccessToast("NPI is Empty. Please select Different Vendor Id")
        //}
        //if (!data.taxId) {
        //    showSuccessToast("TaxId is Empty. Please select Different Vendor Id")
        //}
        try {
            const pointOfContactData = getValuesPointOfContact();
            savePointOfContact(pointOfContactData);
            let action = 'AddNewIpa';
            const effDt = new Date(data.effDate);
            const endDt = new Date(data.endDate);
            data.effDate = `${effDt.getMonth() + 1}/${effDt.getDate()}/${effDt.getFullYear()}`;
            data.endDate = `${endDt.getMonth() + 1}/${endDt.getDate()}/${endDt.getFullYear()}`;
            const adaptedData = {
                ...data,
            };
            for (const d in adaptedData) {
                if (d.includes('mso')) {
                    delete adaptedData[d];
                }
            }
            if (ipa.id) {
                action = 'UpdateIpa';
                adaptedData.id = ipa.id;
            }
            const url = `/api/MedicalGroup/${action}`;
            await requests.post(url, adaptedData);
            showSuccessToast(`IPA ${ipa.id ? 'Updated' : 'Created'} Successfully`);
            tabService.close();
            refreshTabService.performAction('refresh');
        } catch (e) {
            const message = `${(e as AxiosError)?.response?.data}`;
            showErrorToast('Error :', message);
        }
    };
    const fetchVendorInfo = async (vendorId, isVendorChanged) => {
        try {
            const vendordata: VendorInfo = await requests.get(`/api/MedicalGroup/GetVendorData?vendorId=${vendorId}`);
            setContractInfoValue('taxId', vendordata.taxId);
            setContractInfoValue('npi', vendordata.vendorNpi);
            setContractInfoValue('vendorNm', vendordata.vendorName);
            if (isVendorChanged) {
                setContractInfoValue('ipaDesc', vendordata.vendorName);
            }
            setContractInfoValue('addr', vendordata.street);
            setContractInfoValue('street2', vendordata.street2);
            setContractInfoValue('state', vendordata.state);
            setContractInfoValue('phone', vendordata.phone);
            setContractInfoValue('city', vendordata.city);
            setContractInfoValue('email', vendordata.email);
        } catch (e) {
            const message = `${(e as AxiosError)?.response?.data}`;
            showErrorToast(message);
        }
    };
    const copyToNewRecord = () => {
        CreateIpaMember(ipaId, true);
    };
    const Header = () => (
        <>
            {ipa.id ? (
                <div>
                    <div className="ipa-card-content">
                        <section className="responsiveImgContainer">
                            <img
                                src="/images/team.svg"
                                alt="image team"
                                style={{ width: 175, opacity: 0.4, height: 175 }}
                            />
                        </section>
                        <hr className="hideHR" />
                        <section className="detailsWrap">
                            <div className="labelInputWrap">
                                <label className="customLabel">
                                    <b className="boldElementStyle">IPA Name</b>
                                    <b>:</b>
                                </label>
                                <span className="customInputField paddingRight">{ipa.ipaDesc}</span>
                            </div>
                            <div className="labelInputWrap">
                                <label className="customLabel">
                                    <b className="boldElementStyle">IPA Code</b>
                                    <b>:</b>
                                </label>
                                <span className="customInputField paddingRight">{ipa.ipaCode}</span>
                            </div>
                            <div className="labelInputWrap">
                                <label className="customLabel">
                                    <b className="boldElementStyle">Company Code</b>
                                    <b>:</b>
                                </label>
                                <span className="customInputField paddingRight">{ipa.companyCode}</span>
                            </div>
                            <div className="labelInputWrap">
                                <label className="customLabel">
                                    <b className="boldElementStyle">Contract Name</b>
                                    <b>:</b>
                                </label>
                                <span className="customInputField paddingRight">{ipa.contractName}</span>
                            </div>
                            <div className="labelInputWrap">
                                <label className="customLabel">
                                    <b className="boldElementStyle">Vendor Name</b>
                                    <b>:</b>
                                </label>
                                <span className="customInputField paddingRight">{ipa.vendorNm}</span>
                            </div>
                            <div className="labelInputWrap">
                                <label className="customLabel">
                                    <b className="boldElementStyle">MSO Name</b>
                                    <b>:</b>
                                </label>
                                <span className="customInputField paddingRight">{mso.name}</span>
                            </div>
                            <div className="labelInputWrap">
                                <label className="customLabel">
                                    <b className="boldElementStyle">MSO Address</b>
                                    <b>:</b>
                                </label>
                                <span className="customInputField paddingRight">{mso.address1}</span>
                            </div>
                            <div className="labelInputWrap">
                                <label className="customLabel">
                                    <b className="boldElementStyle">MSO Email</b>
                                    <b>:</b>
                                </label>
                                <span className="customInputField paddingRight">{mso?.email}</span>
                            </div>
                            <br />
                            <div className="labelInputWrap">
                                <label className="customLabel">
                                    <b className="boldElementStyle">Created By</b>
                                    <b>:</b>
                                </label>
                                <span className="customInputField paddingRight">{ipa?.createdBy}</span>
                            </div>
                            <div className="labelInputWrap">
                                <label className="customLabel">
                                    <b className="boldElementStyle">Created Date</b>
                                    <b>:</b>
                                </label>
                                <span className="customInputField paddingRight">
                                    {formatISODateTime(ipa?.createdDt)}
                                </span>
                            </div>
                            <br />
                            <div className="labelInputWrap">
                                <label className="customLabel">
                                    <b className="boldElementStyle">Updated By</b>
                                    <b>:</b>
                                </label>
                                <span className="customInputField paddingRight">{ipa?.updatedBy}</span>
                            </div>
                            <div className="labelInputWrap">
                                <label className="customLabel">
                                    <b className="boldElementStyle">Updated Date</b>
                                    <b>:</b>
                                </label>
                                <span className="customInputField paddingRight">
                                    {formatISODateTime(ipa?.updatedDt)}
                                </span>
                            </div>
                        </section>
                    </div>
                </div>
            ) : null}
        </>
    );
    const MainInfo = () => (
        <div style={{ pointerEvents: editFlag ? 'none' : 'auto', padding: '10px 10px 0' }}>
            <Row>
                <Col md={6}>
                    <div className={`e-float-input e-input-group e-input-group-margin`}>
                        <label className="e-float-text boldCss" style={{ marginTop: '-25px' }}>
                            IPA Code
                        </label>
                        <input
                            type="text"
                            maxLength={12}
                            {...contractInfoForm('ipaCode')}
                            readOnly={true}
                            autoFocus={true}
                        />
                        <span className="e-float-line"></span>
                    </div>
                    <Row>
                        <Col md={6}>
                            <div style={{ width: '100%' }} className="datepicker e-input-group-margin">
                                <label className="e-label-select boldCss">Effective Date *</label>
                                <DatePickerComponent
                                    format="MM/dd/yyyy"
                                    enableMask={true}
                                    id="calendar_start"
                                    {...contractInfoForm('effDate', {
                                        required: 'Effective Date is required',
                                    })}
                                    change={({ value }) => setContractInfoValue('effDate', value)}
                                    min={undefined}
                                    max={new Date('12/31/9999')}
                                    value={new Date(ipa.effDate)}
                                    strictMode
                                >
                                    <Inject services={[MaskedDateTime]} />
                                </DatePickerComponent>
                                {errors.effDate && <span className="error-form">{errors.effDate.message}</span>}
                            </div>
                        </Col>

                        <Col md={6}>
                            <div style={{ width: '100%' }} className="datepicker e-input-group-margin">
                                <label className="e-label-select boldCss">End Date *</label>
                                <DatePickerComponent
                                    format="MM/dd/yyyy"
                                    enableMask={true}
                                    id="calendar_end"
                                    {...contractInfoForm('endDate', {
                                        required: 'End Date is required',
                                        validate: (value) => {
                                            const effDate = new Date(getContractInfoValues('effDate'));
                                            const endDate = new Date(value);
                                            if (endDate <= effDate)
                                                return `End date must be Greater than Effective Date`;
                                        },
                                    })}
                                    change={({ value }) => setContractInfoValue('endDate', value)}
                                    min={undefined}
                                    max={new Date('12/31/9999')}
                                    value={new Date('12/31/9999')}
                                    strictMode
                                >
                                    <Inject services={[MaskedDateTime]} />
                                </DatePickerComponent>
                                {errors.endDate && <span className="error-form">{errors.endDate.message}</span>}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className={`e-float-input e-input-group e-input-group-margin`}>
                                <input type="text" {...contractInfoForm('ipaDesc')} />
                                <span className="e-float-line"></span>
                                <label className="e-float-text boldCss">IPA Name / IPA Description</label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className="e-input-group-margin">
                                <div className="control-label label-mask">IPA Phone 1</div>
                                <MaskedTextBoxComponent
                                    mask="(000) 000-0000"
                                    {...contractInfoForm('ipaPhone', {
                                        maxLength: 15,
                                        minLength: 10,
                                    })}
                                />
                            </div>
                            {errors.ipaPhone && (
                                <p className="error-form">Incorrect format phone number (123)123-1234</p>
                            )}
                        </Col>
                        <Col md={6}>
                            <div className="e-input-group-margin">
                                <div className="control-label label-mask">IPA Phone 2</div>
                                <MaskedTextBoxComponent
                                    mask="(000) 000-0000"
                                    {...contractInfoForm('ipaPhone2', {
                                        maxLength: 15,
                                        minLength: 10,
                                    })}
                                />
                            </div>
                            {errors.ipaPhone2 && (
                                <p className="error-form">Incorrect format phone number (123) 123-1234</p>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className={`e-float-input e-input-group e-input-group-margin`}>
                                <input type="text" {...contractInfoForm('ipaNameIdCard')} />
                                <span className="e-float-line"></span>
                                <label className="e-float-text boldCss">Member Card/IPA Name</label>
                            </div>
                        </Col>
                    </Row>
                </Col>

                <Col md={6}>
                    <Row>
                        <Col md={6}>
                            <div className="e-input-group-margin">
                                <label className="e-label-select">Contract Type *</label>
                                <DropDownListComponent
                                    id="risk"
                                    dataSource={risks}
                                    placeholder="Select a Contract Type"
                                    fields={{ text: 'text', value: 'id' }}
                                    change={({ itemData }) => setContractInfoValue('risk', itemData.id)}
                                    popupHeight="220px"
                                    {...contractInfoForm('risk', { required: 'Contract Type is required' })}
                                />
                            </div>
                            {errors.risk && <span className="error-form">{errors.risk.message}</span>}
                        </Col>
                        <Col md={6}>
                            <div className="e-input-group-margin">
                                <label className="e-label-select">Type *</label>
                                <DropDownListComponent
                                    id="type"
                                    dataSource={types}
                                    placeholder="Select a type"
                                    {...contractInfoForm('type', { required: 'Type is required' })}
                                    change={({ value }) => {
                                        setContractInfoValue('type', value);
                                    }}
                                    popupHeight="220px"
                                />
                            </div>
                            {errors.type && <p className="error-form">{errors.type.message}</p>}
                        </Col>
                    </Row>
                    <div className={`e-float-input e-input-group e-input-group-margin`}>
                        <input type="text" {...contractInfoForm('notes')} maxLength={50} />
                        <span className="e-float-line"></span>
                        <label className="e-float-text boldCss">Notes</label>
                    </div>
                    <Row>
                        <Col md={8}>
                            <div className={`e-float-input e-input-group e-input-group-margin`}>
                                <input type="text" {...contractInfoForm('ip')} maxLength={15} />
                                <span className="e-float-line"></span>
                                <label className="e-float-text boldCss">IP</label>
                            </div>
                        </Col>
                        <Col md={4} style={{ display: 'flex' }}>
                            <div style={{ margin: 'auto' }} className="e-input-group-margin">
                                <CheckBoxComponent
                                    checked={ipa?.termed}
                                    label="Hide On Web"
                                    change={({ checked }) => {
                                        setIpa({ ...ipa, termed: checked });
                                        setContractInfoValue('termed', checked, { shouldDirty: true });
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <div className={`e-float-input e-input-group e-input-group-margin`}>
                        <input type="text" {...contractInfoForm('webSite')} maxLength={200} />
                        <button
                            className={'btn btn-primary'}
                            type={'button'}
                            onClick={(e) => {
                                e.preventDefault();
                                if (getContractInfoValues('webSite')) {
                                    const website = '//' + getContractInfoValues('webSite');
                                    window.open(website, '_blank');
                                }
                            }}
                        >
                            <i className="fa fa-search"></i>
                        </button>
                        <span className="e-float-line"></span>
                        <label className="e-float-text boldCss">Website</label>
                    </div>
                    <Row>
                        <Col md={6}>
                            <div className="e-input-group-margin">
                                <div className="control-label label-mask">Member Card/IPA Phone</div>
                                <MaskedTextBoxComponent
                                    mask="(000) 000-0000"
                                    {...contractInfoForm('ipaPhoneIdCard', {
                                        maxLength: 15,
                                        minLength: 10,
                                    })}
                                />
                            </div>
                            {errors.ipaPhoneIdCard && (
                                <p className="error-form">Incorrect format phone number (123)123-1234</p>
                            )}
                        </Col>
                        <Col md={6}>
                            <div className={`e-float-input e-input-group e-input-group-margin`}>
                                <input type="text" {...contractInfoForm('internalPointofContact')} />
                                <span className="e-float-line"></span>
                                <label className="e-float-text boldCss">Internal Point of Contact</label>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );

    const DetailInfo = () => (
        <div>
            <div className="labelInputWrap">
                <label className="customLabel">
                    <b className="boldElementStyle">Vendor ID</b>
                    <b>:</b>
                </label>
                <span className="customInputField paddingRight">{ipa?.vendorId}</span>
            </div>

            <div className="labelInputWrap">
                <label className="customLabel">
                    <b className="boldElementStyle">TAX Id</b>
                    <b>:</b>
                </label>
                <input type="text" {...contractInfoForm('taxId')} className="customInputField paddingRight" />
            </div>

            <div className="labelInputWrap">
                <label className="customLabel">
                    <b className="boldElementStyle">NPI</b>
                    <b>:</b>
                </label>
                <input type="text" {...contractInfoForm('npi')} className="customInputField paddingRight" />
            </div>

            <div className="labelInputWrap">
                <label className="customLabel">
                    <b className="boldElementStyle">Vendor Name</b>
                    <b>:</b>
                </label>
                <span className="customInputField paddingRight">{ipa?.vendorNm ?? ''}</span>
            </div>

            <div className="labelInputWrap">
                <label className="customLabel">
                    <b className="boldElementStyle">Address</b>
                    <b>:</b>
                </label>
                <span {...contractInfoForm('addr')} className="customInputField paddingRight">
                    {ipa?.addr}
                </span>
            </div>

            <div className="labelInputWrap">
                <label className="customLabel">
                    <b className="boldElementStyle">Suite/Bldg/No</b>
                    <b>:</b>
                </label>
                <span {...contractInfoForm('street')} className="customInputField paddingRight">
                    {ipa?.street2}
                </span>
            </div>

            <div className="labelInputWrap">
                <label className="customLabel">
                    <b className="boldElementStyle">City</b>
                    <b>:</b>
                </label>
                <input type="text" {...contractInfoForm('city')} className="customInputField paddingRight" />
            </div>

            <div className="labelInputWrap">
                <label className="customLabel">
                    <b className="boldElementStyle">State</b>
                    <b>:</b>
                </label>
                <input type="text" {...contractInfoForm('state')} className="customInputField paddingRight" />
            </div>

            <div className="labelInputWrap">
                <label className="customLabel">
                    <b className="boldElementStyle">Zip Code</b>
                    <b>:</b>
                </label>
                <input type="text" {...contractInfoForm('zip')} className="customInputField paddingRight" />
            </div>

            <div className="labelInputWrap">
                <label className="customLabel">
                    <b className="boldElementStyle">Fax</b>
                    <b>:</b>
                </label>
                <input type="text" {...contractInfoForm('fax')} className="customInputField paddingRight" />
            </div>

            <div className="labelInputWrap">
                <div className="customLabel">
                    <b className="boldElementStyle">Phone</b>
                    <b>:</b>
                </div>
                <input type="text" {...contractInfoForm('phone')} className="customInputField paddingRight" />
            </div>

            <div className="labelInputWrap">
                <label className="customLabel">
                    <b className="boldElementStyle">Email</b>
                    <b>:</b>
                </label>
                <input type="text" {...contractInfoForm('email')} className="customInputField paddingRight" />
            </div>
        </div>
    );

    const MsoDetails = () => (
        <div>
            <div className="labelInputWrap">
                <label className="customLabel">
                    <b className="boldElementStyle">MSO Name</b>
                    <b>:</b>
                </label>
                <span {...contractInfoForm('msoName')} className="customInputField paddingRight">
                    {mso?.name}
                </span>
            </div>

            <div className="labelInputWrap">
                <label className="customLabel">
                    <b className="boldElementStyle">Address</b>
                    <b>:</b>
                </label>
                <span className="customInputField paddingRight" {...contractInfoForm('msoAddress1')}>
                    {mso?.address1}
                </span>
            </div>

            <div className="labelInputWrap">
                <label className="customLabel">
                    <b className="boldElementStyle">Suite/Bldg/No</b>
                    <b>:</b>
                </label>
                <span className="customInputField paddingRight" {...contractInfoForm('msoAddress2')}>
                    {mso?.address2}
                </span>
            </div>

            <div className="labelInputWrap">
                <label className="customLabel">
                    <b className="boldElementStyle">City</b>
                    <b>:</b>
                </label>
                <input
                    className="customInputField paddingRight"
                    type="text"
                    {...contractInfoForm('msoCity')}
                    defaultValue={mso?.city ?? ''}
                />
            </div>

            <div className="labelInputWrap">
                <label className="customLabel">
                    <b className="boldElementStyle">State</b>
                    <b>:</b>
                </label>
                <input
                    className="customInputField paddingRight"
                    type="text"
                    {...contractInfoForm('msoState')}
                    defaultValue={mso?.state ?? ''}
                />
            </div>

            <div className="labelInputWrap">
                <label className="customLabel">
                    <b className="boldElementStyle">Zip</b>
                    <b>:</b>
                </label>
                <input
                    type="text"
                    {...contractInfoForm('msoZipCode')}
                    defaultValue={mso?.zipCode ?? ''}
                    className="customInputField paddingRight"
                />
            </div>

            <div className="labelInputWrap">
                <label className="customLabel">
                    <b className="boldElementStyle">Phone / Fax</b>
                    <b>:</b>
                </label>
                <span className="customInputField paddingRight">{mso?.phone}</span>
            </div>

            <div className="labelInputWrap">
                <label className="customLabel">
                    <b className="boldElementStyle">Email</b>
                    <b>:</b>
                </label>
                <input
                    type="text"
                    {...contractInfoForm('msoEmail')}
                    defaultValue={mso?.email ?? ''}
                    className="customInputField paddingRight"
                />
            </div>

            <div className="labelInputWrap">
                <label className="customLabel">
                    <b className="boldElementStyle">Notes</b>
                    <b>:</b>
                </label>
                <span {...contractInfoForm('msoNotes')} className="customInputField paddingRight">
                    {mso?.notes}
                </span>
            </div>
        </div>
    );

    const SectionContainer = ({ children, header }) => (
        <div className="section-container">
            <p className="section-container-header">{header}</p>
            {children}
        </div>
    );

    const IPADetail = useCallback(
        () => (
            <DisabledWrapper disabled={isReadOnlyViewer}>
                <div className="tab-content tabDiv p-3">
                    <form onSubmit={onClickContractInfo(saveContractInformation)}>
                        <div>
                            <p className="section-container-header">
                                {editFlag == true ? 'Edit Main Info' : 'Main Info'}{' '}
                            </p>
                            <MainInfo />
                        </div>
                        <Row>
                            <Col md={6}>
                                <Row style={{ paddingLeft: '10px' }}>
                                    <Col md={12} style={{ pointerEvents: editFlag ? 'none' : 'auto' }}>
                                        <div className="mb-2">
                                            <label className="e-label-select boldCss">Vendor ID</label>
                                            <DropDownListComponent
                                                id="Vendor_ID"
                                                {...contractInfoForm('vendorId')}
                                                allowFiltering={true}
                                                popupHeight="220px"
                                                filtering={onFiltering}
                                                sortOrder={sortOrder}
                                                query={query}
                                                dataSource={vendors}
                                                fields={fields}
                                                placeholder="Select Vendor ID"
                                                change={({ isInteracted, value }) => {
                                                    if (isInteracted) {
                                                        fetchVendorInfo(value, true);
                                                    }

                                                    setContractInfoValue('vendorId', value);
                                                }}
                                                readOnly={editFlag}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <div className="fieldsets">
                                    <div className="subHeadding">Vendor Info</div>
                                    <div className="content">
                                        <DetailInfo />
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <Row
                                    style={{
                                        pointerEvents: editFlag ? 'none' : 'auto',
                                        paddingRight: '10px',
                                    }}
                                >
                                    <Col className="field-col" md={4}>
                                        <div className="e-input-group-margin">
                                            <label className="e-label-select boldCss">Company Code</label>
                                            <DropDownListComponent
                                                id="companyCode"
                                                {...contractInfoForm('companyCode', {
                                                    required: 'Company Code is required',
                                                })}
                                                dataSource={companyCodes}
                                                fields={{ text: 'companyName', value: 'companyCode' }}
                                                placeholder="Select a Company Code"
                                                value={getContractInfoValues().companyCode}
                                                change={({ value }) => {
                                                    setContractInfoValue('companyCode', value);
                                                    fetchContracts(value);
                                                }}
                                                popupHeight="220px"
                                                readOnly={editFlag}
                                            />
                                        </div>
                                        {errors.companyCode && (
                                            <span className="error-form">{errors.companyCode.message}</span>
                                        )}
                                    </Col>
                                    <Col className="field-col" md={8}>
                                        <div>
                                            <label className="e-label-select boldCss">Contract Name</label>
                                            <DropDownListComponent
                                                id="contractLevelId"
                                                {...contractInfoForm('contractLevelId', {
                                                    required: 'Contract Name is required',
                                                })}
                                                dataSource={contracts}
                                                placeholder="Select a Contract Name"
                                                fields={{ value: 'id', text: 'text' }}
                                                value={String(getContractInfoValues().contractLevelId)}
                                                change={({ itemData, value }) => {
                                                    setContractInfoValue('contractLevelId', Number(value));
                                                    setContractInfoValue('contractName', itemData.text);
                                                    fetchMso(itemData.id);
                                                }}
                                                popupHeight="220px"
                                                allowFiltering={true}
                                                readOnly={editFlag}
                                            />
                                        </div>
                                        {termed === 'Y' && <span className="error-form">The contract is termed</span>}
                                        {errors.contractName && (
                                            <span className="error-form">{errors.contractName.message}</span>
                                        )}
                                    </Col>
                                </Row>
                                <div className="fieldsets" style={{ marginLeft: '0px', marginTop: '9px' }}>
                                    <div className="subHeadding">Contract & MSO Info</div>
                                    <div className="content" style={{ height: '367px' }}>
                                        <MsoDetails />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div style={{ textAlign: 'right' }}>
                            {ipa.id && (
                                <ButtonComponent
                                    type="button"
                                    onClick={() => copyToNewRecord()}
                                    cssClass="e-primary btn-primary"
                                    style={{ marginRight: '15px', borderRadius: '0px' }}
                                >
                                    Copy to New Record
                                </ButtonComponent>
                            )}
                            {!editFlag && (
                                <ButtonComponent
                                    cssClass="e-primary btn-primary btn-flat btn-create"
                                    style={{ borderRadius: '0px' }}
                                    disabled={!isDirty}
                                >
                                    Save
                                </ButtonComponent>
                            )}
                        </div>
                    </form>
                </div>
            </DisabledWrapper>
        ),
        [ipa, errors, contracts, vendors, mso, companyCodes, termed, editFlag],
    );
    const Tabs = () => (
        <div className="tabDiv medical-group-admin" style={{ margin: '0px', padding: '0px' }}>
            <div className="header-content" style={{ minHeight: '600px' }}>
                {!loading ? (
                    <>
                        <div className="gridHeaderDiv dark blue-border no-top-border">
                            <div className="card-title-item">
                                <b>ID: </b>
                                {ipa.id}
                            </div>
                            <div className="card-title-item">
                                <b>Company Code: </b>
                                {ipa.companyCode}
                            </div>

                            <div className="card-title-item">
                                <b>Risk ID: </b>
                                {ipa.risk}
                            </div>

                            <div className="card-title-item">
                                <b>NPI: </b>
                                {ipa.vendorNpi}
                            </div>
                            <div className="card-title-item">
                                <b>Tax ID: </b>
                                {ipa.taxId}
                            </div>
                            <div className="card-title-item">
                                <b>Vendor ID: </b>
                                {ipa.vendorId}
                            </div>
                        </div>
                        <div className="row mt-3 m-2">
                            <div className="col-md-3">
                                <div className="leftContainer">
                                    <Header />
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="rightContainer p-3">
                                    <TabComponent {...tabDefaultSettings} heightAdjustMode="Auto" id="defaultTab">
                                        {ipa.id && (
                                            <TabItemsDirective>
                                                <TabItemDirective
                                                    header={{ text: 'IPA Details' }}
                                                    content={() => <IPADetail />}
                                                />
                                                {networkMappingFlag && (
                                                    <TabItemDirective
                                                        header={{ text: 'Network Mapping' }}
                                                        content={() => (
                                                            <IpaNetworkMappingTab
                                                                ipa={ipa}
                                                                isReadOnlyViewer={isReadOnlyViewer}
                                                            />
                                                        )}
                                                    />
                                                )}
                                                {hospitalFlag && (
                                                    <TabItemDirective
                                                        header={{ text: 'Hospital' }}
                                                        content={() => (
                                                            <IpaHospitalTab ipa={ipaCode} companyId={ipa.companyCode} />
                                                        )}
                                                    />
                                                )}
                                                <TabItemDirective
                                                    header={{ text: 'Additional Contact' }}
                                                    content={() => (
                                                        <AdditionalContactTab
                                                            id={ipaId}
                                                            isReadOnlyViewer={isReadOnlyViewer}
                                                            risk={ipa.risk}
                                                        />
                                                    )}
                                                />
                                                <TabItemDirective
                                                    header={{ text: 'PBP Map' }}
                                                    content={() => (
                                                        <IpaPlanMappingTab
                                                            companyCode={ipa.companyCode}
                                                            ipaId={ipaId}
                                                            ipaCode={ipaCode}
                                                            isReadOnlyViewer={isReadOnlyViewer}
                                                        />
                                                    )}
                                                />
                                                {countyFlag && (
                                                    <TabItemDirective
                                                        header={{ text: 'County Map' }}
                                                        content={() => (
                                                            <IpaCountyMappingTab
                                                                companyCode={ipa.companyCode}
                                                                ipaId={ipaId}
                                                                ipaCode={ipaCode}
                                                                isReadOnlyViewer={isReadOnlyViewer}
                                                                state={ipa.state}
                                                            />
                                                        )}
                                                    />
                                                )}
                                                {dofrFlag && (
                                                    <TabItemDirective
                                                        header={{ text: 'DOFR' }}
                                                        content={() => (
                                                            <DOFRContract
                                                                ipa={ipa}
                                                                isReadOnlyViewer={isReadOnlyViewer}
                                                            />
                                                        )}
                                                    />
                                                )}
                                                <TabItemDirective
                                                    header={{ text: 'Cap Rates' }}
                                                    content={() => (
                                                        <CapRatesTab
                                                            ipa={ipa}
                                                            ipaId={ipaId}
                                                            ipaCode={ipaCode}
                                                            isReadOnlyViewer={isReadOnlyViewer}
                                                        />
                                                    )}
                                                />
                                                {riskFlag && (
                                                    <TabItemDirective
                                                        header={{ text: 'Risk Pool Rates' }}
                                                        content={() => (
                                                            <RiskPoolRatesTab
                                                                ipa={ipa}
                                                                ipaId={ipaId}
                                                                ipaCode={ipaCode}
                                                                isReadOnlyViewer={isReadOnlyViewer}
                                                            />
                                                        )}
                                                    />
                                                )}
                                                {gainShareFlag && (
                                                    <TabItemDirective
                                                        header={{ text: 'Gain Share Rates' }}
                                                        content={() => (
                                                            <GainShareRatesTab
                                                                ipa={ipa}
                                                                ipaId={ipaId}
                                                                ipaCode={ipaCode}
                                                                isReadOnlyViewer={isReadOnlyViewer}
                                                            />
                                                        )}
                                                    />
                                                )}
                                            </TabItemsDirective>
                                        )}
                                    </TabComponent>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div>
                            <div
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    backgroundColor: 'lightgray',
                                    opacity: '0.1',
                                    position: 'absolute',
                                    zIndex: '99999',
                                }}
                            ></div>
                            <div
                                className="spinner-border"
                                style={{
                                    position: 'absolute',
                                    top: '30vh',
                                    left: 'calc(50% - 20px)',
                                    height: '50px',
                                    width: '50px',
                                    color: 'rgb(227, 22, 91)',
                                }}
                            ></div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );

    return <Tabs />;
}

export default IpaAdministrationDetailView;
