import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import '../NavMenu/NavMenu.css';
import Footer from './Footer';
import Header from './Header';
import DialogPlaceholder from '../Common/DialogPlaceholder';
import GlobalDialog from '../Common/GlobalDialog';
import Notification from '../Common/Notification';
import PopupWindow from '../Common/PopupWindow';
import VirtualVisitModal from '../Common/VirtualVisits/VirtualVisitModal';
import NavMenu from '../NavMenu/NavMenu';
class Layout extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Header />
                <NavMenu />
                <div>
                    <div className="main-content">
                        <Container>{this.props.children}</Container>
                        <Notification />
                        <GlobalDialog />
                        <DialogPlaceholder />
                        <PopupWindow />
                    </div>
                    <Footer />
                </div>
                <VirtualVisitModal />
            </React.Fragment>
        );
    }
}

export { Layout as UnconnectedLayout };
export default connect()(Layout);
