import React, { useEffect, useRef, useState } from 'react';
import { ColumnChooser, GridComponent, Inject, Page, Selection, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { MemberEnrollmentApi } from 'components/AvaEzm/MemberEnrollment/MemberEnrollmentApi';
import 'components/AvaEzm/MemberEnrollment/AdminAndSupport/AdminAndSupport.scss';
import {
    EnrollmentIntakeIds,
    WebEnrollmentGridColumns,
} from 'components/AvaEzm/MemberEnrollment/MemberEnrollmentDetails/MemberEnrollmentConfig';
import useToast from 'hooks/useToast';
import { DialogUtility } from '@syncfusion/ej2-popups';
import { dialogService } from 'subjects/common/DialogService';

const WebEnrollmentAdminPortal = () => {
    const loaderRef = useRef<HTMLDivElement>(null);
    const webEnrollmentGridRef = useRef<GridComponent>(null);
    const [webEnrollmentData, setWebEnrollmentData] = useState<any>([]);
    const [selectedEnrollments, setSelectedEnrollments] = useState<any>([]);
    const { showErrorToast, showSuccessToast } = useToast();

    const tabsToolbarOptions = [
        {
            id: 'requeue',
            text: 'Requeue',
            tooltipText: 'Requeue',
            align: 'Left',
        },
    ];

    const showLoader = (show: boolean) => {
        if (loaderRef.current) {
            loaderRef.current.style.display = show ? 'block' : 'none';
        }
    };

    const updateDataSource = async () => {
        await MemberEnrollmentApi.GetLockedEnrollments().then((data) => {
            setWebEnrollmentData(data);
        });
    };

    const handleDataGridSelection = () => {
        const selectedRows = webEnrollmentGridRef?.current?.getSelectedRecords() || [];
        setSelectedEnrollments(selectedRows);
    };

    const toolbarClick = ({ item }) => {
        if (selectedEnrollments && selectedEnrollments.length > 0) {
            const confirm = DialogUtility.confirm({
                animationSettings: { effect: 'None' },
                isModal: true,
                content: '<b>Are you sure you want to requeue the selected enrollments to service bus?</b>',
                cancelButton: {
                    text: 'NO',
                    cssClass: 'e-cancel mt-2',
                    click: () => {
                        confirm.hide();
                    },
                },
                okButton: {
                    text: 'YES',
                    cssClass: 'e-submit mt-2',
                    click: () => {
                        requeueSelectedEnrollment();
                        confirm.hide();
                    },
                },
                showCloseIcon: true,
                position: { X: 'center', Y: 'center' },
            });
        } else {
            const alter = DialogUtility.alert({
                animationSettings: { effect: 'None' },
                isModal: true,
                closeOnEscape: true,
                content: 'No enrollment was selected.',
                okButton: {
                    text: 'OK',
                    cssClass: 'e-flat e-info',
                    click: () => alter.hide(),
                },
                showCloseIcon: false,
                position: { X: 'center', Y: 'center' },
            });
        }
    };

    const requeueSelectedEnrollment = async () => {
        const selectedIds = selectedEnrollments.map((enrollment) => enrollment.id).join(',');

        const data = {
            enrollmentIds: selectedIds,
        } as EnrollmentIntakeIds;

        showLoader(true);
        dialogService.loading(true);

        await MemberEnrollmentApi.SendEnrollmentsToServiceBus(data)
            .then((res) => {
                resetStatusForSelectedEnrollment(data);
                showSuccessToast(`Send the enrollments (${data['enrollmentIds']}) to service bus successfully`);
            })
            .catch((e) => {
                console.error(e);
                showErrorToast(
                    `Failed to send the enrollment (${data['enrollmentIds']}) to service bus. Please try again later`,
                );
            })
            .finally(() => {
                showLoader(false);
                dialogService.loading(false);
            });
    };

    const resetStatusForSelectedEnrollment = async (data: EnrollmentIntakeIds) => {
        await MemberEnrollmentApi.resetEnrollmentStatus(data)
            .then((res) => {
                updateDataSource();
            })
            .catch((e) => {
                console.error(e);
                showErrorToast(
                    `Failed to reset the status for the enrollment (${data['enrollmentIds']}). Please try again later`,
                );
            });
    };

    useEffect(() => {
        updateDataSource();
    }, []);

    return (
        <div className="tab-content tabDiv p-3">
            <div className="grid-container">
                <GridComponent
                    id="enrollmentGridRef"
                    height="auto"
                    rowHeight={48}
                    allowSorting={true}
                    allowPaging={true}
                    columns={WebEnrollmentGridColumns}
                    dataSource={webEnrollmentData}
                    toolbar={tabsToolbarOptions as any}
                    ref={webEnrollmentGridRef}
                    showColumnChooser={true}
                    rowSelected={handleDataGridSelection}
                    rowDeselected={handleDataGridSelection}
                    toolbarClick={toolbarClick}
                >
                    <Inject services={[Page, Selection, Sort, Toolbar, ColumnChooser]} />
                </GridComponent>
            </div>
        </div>
    );
};

export default WebEnrollmentAdminPortal;
