import { BehaviorSubject } from 'rxjs';
import { ajaxGet, ajaxPost } from 'common/ajax';

const currentUser = new BehaviorSubject({});
const modules = new BehaviorSubject([]);

const currentUserService = {
    get: () => currentUser.asObservable(),
    moduleIds$: modules.asObservable(),
    logout: () => ajaxPost('api/me/logout', {}).subscribe(),
    track: (eventType, properties) => {
        if (!eventType) {
            return;
        }

        return ajaxPost('api/me/track', { eventType, ...properties }).subscribe();
    },
    getSessionOrganizations: () => ajaxGet(`/odata/sessionorganizations?OrgType=IPA`),
};

export { currentUser, currentUserService, modules };
