import { useEffect, useState } from 'react';
import { DataManager, WebApiAdaptor } from '@syncfusion/ej2-data';
import { GridComponent, QueryCellInfoEventArgs } from '@syncfusion/ej2-react-grids';

import { getAvaDataManager } from 'common/AVADataManager';
import { getFilterURLParameterValues, memberDataTypes } from 'common/widgets';
import {
    IpaAdministrationSearchFilters,
    IpaAdministrationSearchGridColumns,
    toolbarOptions,
} from 'components/ProviderData/IpaAdministration/atoms/IpaAdministration.Metadata';

import AdvancedSearchComponent from 'components/Common/AdvancedSearch';
import useToast from 'hooks/useToast';
import {
    refreshTabService,
    selectedIpaAdministrationService,
} from 'components/ProviderData/subjects/IpaAdministrationService';
import { dialogService } from 'subjects/common/DialogService';
import { CreateIpaMember } from 'components/ProviderData/IpaAdministration/organisms/IpaAdministrationWizard';
import { getItems } from 'components/AvaEzm/Utilities/ODataUtility';
import useUserGroups from 'hooks/useUserGroups';
import 'css/styles.css';
import IpaDataImportForm from 'components/ProviderData/DataImportUtility/IpaDataImportForm';
import IpaDataExportForm from 'components/ProviderData/DataImportUtility/IpaDataExportForm';
import { Spinner } from 'react-bootstrap';
import { countPropertiesWithValues } from 'components/ProviderData/utils/utils';

const SEARCH_URL = 'api/MedicalGroup/SearchForIpaOrVendorsPaginated';

const INITIAL_FILTER = { ipaStatus: '1' };

const IPA_STATUS = [
    { value: '0', text: 'Show Active Only' },
    { value: '1', text: 'Show All' },
];

const IpaAdministrationSearch = () => {
    const [filters, setFilters] = useState(IpaAdministrationSearchFilters);
    const { showSuccessToast, showErrorToast } = useToast();
    const [isReadOnlyViewer, setIsReadOnlyViewer] = useState(false);
    const [dataExportImportUtilityFlag, setDataExportImportUtilityFlag] = useState(false);
    const { belongToGroupName } = useUserGroups();
    const [searchFilters, setSearchFilters] = useState<any[]>([]);
    const [ipaAdminData, setIpaAdminData] = useState<DataManager | any[]>([]);
    const [filterDataIsLoaded, setFilterDataIsLoaded] = useState(false);

    const _toolbarOptions = [
        {
            id: 'Import',
            text: 'Import',
            tooltipText: 'Import Data',
            prefixIcon: 'e-file',
            align: 'Left',
        },
        {
            id: 'Export',
            text: 'Export',
            tooltipText: 'Export Data',
            prefixIcon: 'e-excelexport',
            align: 'Left',
        },
        ...toolbarOptions,
    ];

    let gridInstance: GridComponent;
    const getAsyncFilters = async (_companyCode?) => {
        const _contractTypes = await getItems(`/api/MedicalGroup/GetIpaRisks`);
        const _ipaCodes = await getItems(`/api/MedicalGroup/SearchForIpa`);
        const _companyCodes = await getItems<{ companyCode: string; companyName: string }[]>(
            '/api/MedicalGroup/GetCompanyCodes',
        );

        setFilters(
            filters.map((f) => {
                if (f.id === 'companyCode') {
                    return {
                        ...f,
                        data: _companyCodes,
                    };
                } else if (f.id === 'ipaStatus') {
                    return {
                        ...f,
                        data: IPA_STATUS,
                    };
                } else if (f.id === 'ipaCode') {
                    return {
                        ...f,
                        data: _ipaCodes?.map((i: any) => ({
                            ipaCode: i.ipaCode,
                            ipaDesc: `${i.ipaCode} - ${i.ipaDesc}`,
                        })),
                        fields: { text: 'ipaDesc', value: 'ipaCode' },
                    };
                } else if (f.id === 'contractType') {
                    return {
                        ...f,
                        data: _contractTypes,
                        fields: { text: 'text', value: 'id' },
                    };
                }
                return f;
            }),
        );
        setFilterDataIsLoaded(true);
    };

    const fetchFeatureFlags = async () => {
        const flags: any = await getItems<any>('/api/MedicalGroup/GetNetkworkAdminFeatureFlags');
        const _dataExportImportUtilityFlag = flags.find((f) => f.name === 'enable-export-import-utility');

        setDataExportImportUtilityFlag(_dataExportImportUtilityFlag?.isActive);
    };

    const fetchDataToUpdateDataSource = (_filters: any, gridInstance: GridComponent) => {
        if (filterDataIsLoaded) {
            if (countPropertiesWithValues(_filters) <= countPropertiesWithValues(INITIAL_FILTER)) {
                showErrorToast('Please, select at least one filter before searching the data');
                return;
            }

            gridInstance.showSpinner();
            gridInstance.dataSource = [];
            const parameters = getFilterURLParameterValues({ ..._filters });
            setSearchFilters(_filters);

            setIpaAdminData(getAvaDataManager(`${SEARCH_URL}?${parameters.join('&')}`, WebApiAdaptor));
            gridInstance.hideSpinner();
        }
    };

    const openDialog = (action: string) => {
        const dialog: any = {};
        dialog.header = `${action} Data`;
        if (action === 'Import') {
            dialog.content = () => (
                <IpaDataImportForm
                    showSuccessToast={showSuccessToast}
                    showErrorToast={showErrorToast}
                    isReadOnlyViewer={isReadOnlyViewer}
                />
            );
        } else {
            dialog.content = () => (
                <IpaDataExportForm searchFilters={searchFilters} isReadOnlyViewer={isReadOnlyViewer} />
            );
        }
        dialog.width = '600px';
        dialogService.open(dialog);
    };

    const onToolbarClick = (args) => {
        if (args.item.id === 'Import' || args.item.id === 'Export') {
            openDialog(args.item.id);
        } else if (args.item.id === 'create-new') {
            CreateIpaMember(null, null);
        }
    };

    const updateDataToShowOnGridCell = (args: QueryCellInfoEventArgs) => {
        const dataAndCellHaveValue = args.data && args.cell;

        if (args.column?.field === 'risk' && dataAndCellHaveValue) {
            const riskText = filters
                ?.find((filter) => filter.id === 'contractType')
                ?.data?.find((contractTypeProperty) => contractTypeProperty.id === args.cell!.innerHTML)?.text;

            if (riskText) {
                args.cell!.innerHTML = riskText;
            }
        }
    };

    useEffect(() => {
        gridInstance.queryCellInfo = updateDataToShowOnGridCell;
    }, [filters]);

    useEffect(() => {
        if (belongToGroupName('Provider Data Viewers') && !belongToGroupName('Provider Network Manager'))
            setIsReadOnlyViewer(true);
    }, [belongToGroupName]);

    useEffect(() => {
        fetchFeatureFlags();
        const controller = new AbortController();
        getAsyncFilters();
        refreshTabService.getAction().subscribe((action) => {
            if (action == 'refresh') {
                fetchDataToUpdateDataSource(filters, gridInstance);
            }
        });

        return () => {
            controller.abort();
        };
    }, []);

    return (
        <div
            className={`provider-data-ipa-admin-search${
                isReadOnlyViewer ? ' view-only' : !dataExportImportUtilityFlag ? ' hide-datatooloptions' : ''
            }`}
            id="provider-data-ipa-admin-search"
        >
            {!filterDataIsLoaded && (
                <Spinner
                    animation="border"
                    style={{
                        position: 'absolute',
                        top: 'calc(50% - 20px)',
                        left: 'calc(50% - 20px)',
                        height: '40px',
                        width: '40px',
                        color: '#e3165b',
                        zIndex: '1',
                    }}
                />
            )}
            <AdvancedSearchComponent
                ref={(searchGridRef) => {
                    if (searchGridRef?.gridTemplateInstance) {
                        gridInstance = (searchGridRef?.gridTemplateInstance as any).gridInstance;
                    }
                }}
                sortSettings={[]}
                memberDataType={memberDataTypes.generic}
                columns={IpaAdministrationSearchGridColumns}
                staticFilters={filters}
                toolbarOptions={_toolbarOptions}
                toolbarClick={onToolbarClick}
                dynamicFilters={[]}
                filterWidths={['col-lg-10', 'col-lg-2']}
                updateDataSource={fetchDataToUpdateDataSource}
                selectedItemService={selectedIpaAdministrationService}
                initialFilter={INITIAL_FILTER}
                createIpaMember={CreateIpaMember}
                dataManager={ipaAdminData}
            />
        </div>
    );
};

export default IpaAdministrationSearch;
