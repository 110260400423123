import { ajaxGet, ajaxGetType } from '../../common/ajax';

const configurationService = {
    getFeatureFlags$: (id) => ajaxGet(`api/config/GetFeatureFlags/${id}`),

    getAppSetting$: (id) => ajaxGetType(`api/config/GetConfig/${id}`, 'text'),

    getAESetting$: (variableKey) => ajaxGet(`api/config/GetAESetting/${variableKey}`),

    getAminFlags$: () => ajaxGet(`api/admin/applications/`),

    getUMSetting$: (variableKey) => ajaxGet(`api/um/settings/${variableKey}`),

    getCMUMSetting$: (variableKey) => ajaxGet(`api/cmauth/settings/${variableKey}`),
};

export { configurationService };
