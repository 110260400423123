import React, { useState, useEffect, useRef } from 'react';
import {
    faCaretDown,
    faCaretRight,
    faClipboard,
    faPhone,
    faSpinner,
    faSms,
    faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { notificationService } from '../../../../subjects/common/NotificationService';
import { ajaxGet, ajaxPost } from '../../../../common/ajax';
import './MeetingDetails.css';
import { TELEHEALTH_MODULES_TO_URL } from '../MyPanelHelpers';

const MeetingDetails = ({
    conferenceId,
    phoneNumber,
    appointmentId,
    memberId,
    memberConnectionId,
    memberFullName,
    memberPhone,
    optionalAttendees,
    participants,
    telehealthModuleType,
}) => {
    const [showDetails, setShowDetails] = useState(true);
    const [showLinks, setShowLinks] = useState(false);
    const [telehealthLink, setTelehealthLink] = useState(null);
    const optionalParticipantsRef = useRef(
        optionalAttendees.map((attendee) => {
            return { ...attendee, isCalling: attendee.isCalling };
        })
    );
    const [isMemberCalling, setIsMemberCalling] = useState(false);

    const telehealthSubscription: any = useRef(null);
    const memberTooltipRef: any = useRef(null);
    const optionalTooltipRef: any = useRef(null);
    const resendSMSSubscription: any = useRef(null);
    const resendEmailSubscription: any = useRef(null);
    const placeOutboundCallSubscription: any = useRef(null);
    let timeout;

    useEffect(() => {
        //check if there are pending calls
        const participantArray = [...optionalParticipantsRef.current];

        const pendingCalls = participantArray.filter((attendee) => attendee.isCalling);

        if (pendingCalls && pendingCalls.length > 0) {
            // participant has joined?
            pendingCalls.forEach((attendee) => {
                const participant = participants.find(
                    (participant) => participant.identity === attendee.fullName
                );
                if (participant) {
                    attendee.isCalling = false;
                }
            });
        }

        if (isMemberCalling) {
            const participant = participants.find(
                (participant) => participant.identity === memberFullName
            );
            if (participant) {
                setIsMemberCalling(false);
            }
        }

        optionalParticipantsRef.current = [...participantArray];
    }, [participants, memberFullName, isMemberCalling]);

    useEffect(() => {
        return () => {
            telehealthSubscription &&
                telehealthSubscription.current &&
                telehealthSubscription.current.unsubscribe();

            resendSMSSubscription &&
                resendSMSSubscription.current &&
                resendSMSSubscription.current.unsubscribe();

            resendEmailSubscription &&
                resendEmailSubscription.current &&
                resendEmailSubscription.current.unsubscribe();

            placeOutboundCallSubscription &&
                placeOutboundCallSubscription.current &&
                placeOutboundCallSubscription.current.unsubscribe();

            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, []);

    const handleHover = (isMember) => {
        if (isMember) {
            memberTooltipRef.current.content = 'Copy invitation link';
        } else {
            optionalTooltipRef.current.content = 'Copy invitation link';
        }
    };

    const getLink = (connectionId, isMember = false) => {
        if (telehealthLink) {
            const link = `${telehealthLink}/${appointmentId}/${connectionId}`;
            navigator.clipboard.writeText(link || '');
        } else {
            telehealthSubscription.current = ajaxGet(
                `/api/${TELEHEALTH_MODULES_TO_URL[telehealthModuleType]}/TelehealthLink`
            ).subscribe({
                next: (response) => {
                    if (response.response) {
                        window.focus();
                        const link = `${response.response}/${appointmentId}/${connectionId}`;
                        navigator.clipboard.writeText(link || '');
                        setTelehealthLink((response as any).response);
                    }
                },
                error: console.error,
            });
        }

        // change tooltip text
        if (isMember && memberTooltipRef.current) {
            memberTooltipRef.current.content = 'Text is copied!';
        } else if (!isMember && optionalTooltipRef.current) {
            optionalTooltipRef.current.content = 'Text is copied!';
        }
    };

    const checkCallingStatus = (connectionId, isMember = false, time = 31000) => {
        if (isMember) {
            timeout = setTimeout(() => setCallingLoading(connectionId, isMember, false), time);
        } else {
            timeout = setTimeout(() => setCallingLoading(connectionId, isMember, false), time);
        }
    };

    const setCallingLoading = (connectionId, isMember = false, isCalling = true) => {
        if (isMember) {
            setIsMemberCalling(isCalling);
        } else {
            optionalParticipantsRef.current = optionalParticipantsRef.current.map((attendee) => {
                if (attendee.connectionId === connectionId) {
                    attendee.isCalling = isCalling;
                    return attendee;
                }
                return attendee;
            });
        }
    };

    const outboundCall = (connectionId, isMember, phoneNumber, telehealthModuleType) => {
        setCallingLoading(connectionId, isMember);

        placeOutboundCallSubscription.current = ajaxPost(
            `api/${TELEHEALTH_MODULES_TO_URL[telehealthModuleType]}/PlaceOutboundCall?appointmentId=${appointmentId}&memberId=${memberId}&connectionId=${connectionId}`
        ).subscribe({
            next: (response) => {
                if (response) {
                    checkCallingStatus(connectionId, isMember);
                }
            },
            error: () => {
                setCallingLoading(connectionId, isMember, false);
                notificationService.error({
                    title: `Invalid phone number <br /> ${phoneNumber}`,
                });
            },
        });
    };

    const onSendSMSClick = (args, memberId, appointmentId, telehealthModuleType) => {
        return ajaxGet(
            `api/${TELEHEALTH_MODULES_TO_URL[telehealthModuleType]}/SendSMS?appointmentId=${appointmentId}&memberId=${memberId}&receiverNumber=${args.phone}&connectionId=${args.connectionId}&moduleType=${telehealthModuleType}`
        );
    };

    const handleSendSMS = (args, memberId, appointmentId, telehealthModuleType) => {
        resendSMSSubscription.current = onSendSMSClick(args, memberId, appointmentId, telehealthModuleType).subscribe({
            next: (response) => {
                if (response.status === 200) {
                    notificationService.success({ title: `SMS has been sent to ${args.phone}.` });
                }
            },
            error: (args) => {
                notificationService.error({ title: 'Failed to send SMS. Please try later.' });
            },
        });
    };

    const onResendInvite = (payload) => {
        return ajaxPost(`api/${TELEHEALTH_MODULES_TO_URL[payload.moduleType]}/ResendInvite`, payload);
    };

    const handleSendEmail = (props) => {
        const payload = {
            memberId: props.memberId,
            id: props.appointmentId,
            receiverConnectionId: props.connectionId,
            isPatient: props.isPatient,
            moduleType: props.telehealthModuleType,
        };

        resendEmailSubscription.current = onResendInvite(payload).subscribe({
            next: (response) => {
                if (response.status === 200) {
                    notificationService.success({
                        title: 'The appointment invite has been resent successfully',
                    });
                } else {
                    notificationService.error({
                        title: 'Failed to resend appointment invite, please try again later.',
                    });
                }
            },
            error: () => {
                notificationService.error({
                    title: 'Failed to resend appointment invite, please try again later.',
                });
            },
        });
    };

    return (
        <div className="meeting-details">
            <div
                className="meeting-details-title"
                onClick={() => setShowDetails((prevState) => !prevState)}
            >
                <span>Meeting Details</span>
                <span className="caret-info">
                    <FontAwesomeIcon icon={showDetails ? faCaretDown : faCaretRight} size="lg" />
                </span>
            </div>
            {showDetails && (
                <div className="meeting-details-content">
                    <div>Dial in manually: {phoneNumber}</div>
                    <div>Conference Id: {conferenceId}</div>
                    <div
                        className="meeting-details-links-title"
                        onClick={() => setShowLinks((prevState) => !prevState)}
                    >
                        <span>Appointment links</span>
                        <span className="caret-info">
                            <FontAwesomeIcon icon={showLinks ? faCaretDown : faCaretRight} size="lg" />
                        </span>
                    </div>
                    {showLinks && (
                        <div className="appointment-invitation-links">
                            <div className="member-invitation-links">
                                <span>{memberFullName}</span>
                                <div
                                    className="caret-info virtual-visits-meeting-icon"
                                    onClick={() => getLink(memberConnectionId, true)}
                                    onMouseEnter={() => handleHover(true)}
                                >
                                    <TooltipComponent
                                        ref={memberTooltipRef}
                                        content="Copy invitation link"
                                        className="virtual-visits-meeting-icon"
                                        position="TopCenter"
                                    >
                                        <FontAwesomeIcon icon={faClipboard} size="xl" />
                                    </TooltipComponent>
                                </div>
                                {memberPhone && (
                                    <>
                                        <div
                                            className="caret-info phone-attendee virtual-visits-meeting-icon"
                                            onClick={() =>
                                                outboundCall(
                                                    memberConnectionId,
                                                    true,
                                                    memberPhone,
                                                    telehealthModuleType
                                                )
                                            }
                                        >
                                            {isMemberCalling ? (
                                                <FontAwesomeIcon icon={faSpinner} spin={true} />
                                            ) : (
                                                <TooltipComponent
                                                    className="virtual-visits-meeting-icon"
                                                    content="Call attendee"
                                                    position="TopCenter"
                                                >
                                                    <FontAwesomeIcon icon={faPhone} size="xl" />
                                                </TooltipComponent>
                                            )}
                                        </div>
                                        <div className="caret-info sms-attendee">
                                            <TooltipComponent
                                                content="Resend invitation SMS"
                                                position="TopCenter"
                                                className="virtual-visits-meeting-icon"
                                            >
                                                <FontAwesomeIcon
                                                    title='Resend invitation SMS'
                                                    icon={faSms}
                                                    size="xl"
                                                    onClick={() =>
                                                        handleSendSMS(
                                                            {
                                                                phone: memberPhone,
                                                                connectionId: memberConnectionId,
                                                            },
                                                            memberId,
                                                            appointmentId,
                                                            telehealthModuleType)
                                                    }
                                                />
                                            </TooltipComponent>
                                        </div>
                                    </>
                                )}
                                <div className="caret-info email-attendee">
                                    <TooltipComponent
                                        content="Resend invitation email"
                                        position="TopCenter"
                                        className="virtual-visits-meeting-icon"
                                    >
                                        <FontAwesomeIcon
                                            title='Resend invitation email'
                                            icon={faEnvelope}
                                            size="xl"
                                            onClick={() =>
                                                handleSendEmail({
                                                    appointmentId,
                                                    memberId,
                                                    connectionId: memberConnectionId,
                                                    isPatient: true,
                                                    telehealthModuleType,
                                                })
                                            }
                                        />
                                    </TooltipComponent>
                                </div>
                            </div>
                            {optionalParticipantsRef.current &&
                                optionalParticipantsRef.current.length > 0 &&
                                optionalParticipantsRef.current
                                    .filter((optional) => optional.relationship != 'Admin')
                                    .map((attendee, index) => {
                                        return (
                                            <div key={`optional-attendee-${index}`}>
                                                <span>{attendee.fullName}</span>
                                                <span
                                                    className="caret-info"
                                                    onClick={() =>
                                                        getLink(attendee.connectionId, false)
                                                    }
                                                    onMouseEnter={() => handleHover(false)}
                                                >
                                                    <TooltipComponent
                                                        ref={optionalTooltipRef}
                                                        content="Copy invitation link"
                                                        className="virtual-visits-meeting-icon"
                                                        position="TopCenter"
                                                    >
                                                        <FontAwesomeIcon icon={faClipboard} size="xl" />
                                                    </TooltipComponent>
                                                </span>
                                                {attendee.phone && (
                                                    <span
                                                        className="caret-info phone-attendee"
                                                        onClick={() =>
                                                            outboundCall(
                                                                attendee.connectionId,
                                                                false,
                                                                attendee.phone,
                                                                telehealthModuleType
                                                            )
                                                        }
                                                    >
                                                        {attendee.isCalling ? (
                                                            <FontAwesomeIcon
                                                                icon={faSpinner}
                                                                spin={true}
                                                            />
                                                        ) : (
                                                            <TooltipComponent
                                                                content="Call attendee"
                                                                position="TopCenter"
                                                                className="virtual-visits-meeting-icon"
                                                            >
                                                                <FontAwesomeIcon icon={faPhone} size="xl" />
                                                            </TooltipComponent>
                                                        )}
                                                    </span>
                                                )}
                                            </div>
                                        );
                                    })}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default MeetingDetails;
