import { useEffect, useState } from 'react';
import { RadioButtonComponent, ChipListComponent } from '@syncfusion/ej2-react-buttons';
import { GridComponent, Group, Inject } from '@syncfusion/ej2-react-grids';
import { requests } from 'common/axios';
import { formatISOLocalDateTime } from 'common/widgets';
import {
    RateChangeHistory,
    RiskPoolTieredRate,
    RiskPoolRateDetail,
} from 'components/ProviderData/IpaAdministration/atoms/Models';
import { showConfirmation } from 'components/ProviderData/IpaAdministration/atoms/utils';
import {
    RiskPoolChangeHistoryColumns,
    HistoryViewMode,
    RiskPoolChangeHistory,
    BASE_URI,
} from 'components/ProviderData/IpaAdministration/organisms/RiskPoolRates/RiskPoolRates.Metadata';
import styles from 'components/ProviderData/IpaAdministration/organisms/RiskPoolRates/RiskPoolRates.module.scss';
import DisabledWrapper from 'components/ProviderData/utils/DisabledWrapper';
import useToast from 'hooks/useToast';
import { dialogService } from 'subjects/common/DialogService';

const GAIN_SHARE_FETCH_URI = '/GetRiskPoolRateChangeHistory';
const GAIN_SHARE_TIERED_FETCH_URI = '/GetRiskPoolTieredRateChangeHistory';
const GAIN_SHARE_APPROVE_URI = '/ApproveRiskPoolRateHistoryChanges';
const GAIN_SHARE_TIERED_APPROVE_URI = '/ApproveRiskPoolTieredRateHistoryChanges';

type ChangeHistoryGridProps = {
    isReadOnlyViewer: boolean;
    riskPoolData: RiskPoolRateDetail | RiskPoolTieredRate;
    setPendingSelection: (data) => void;
    approvalCallback: () => void;
};
const RiskPoolRateChangeHistoryGrid = ({
    isReadOnlyViewer,
    riskPoolData,
    setPendingSelection,
    approvalCallback,
}: ChangeHistoryGridProps) => {
    const [fullHistory, setFullHistory] = useState<RateChangeHistory[]>([]);
    const [pendingHistory, setPendingHistory] = useState<RateChangeHistory[]>([]);
    const [dataSource, setDataSource] = useState<RiskPoolChangeHistory[]>([]);
    const [viewMode, setViewMode] = useState<HistoryViewMode>(HistoryViewMode.Pending);
    const [loading, setLoading] = useState(false);
    const { showErrorToast, showSuccessToast } = useToast();

    const fetchChangeHistory = () => {
        dialogService.loading(true);
        const params = { ipaCode: riskPoolData.ipaCode, objectId: riskPoolData.id };
        requests
            .get<RateChangeHistory[]>(
                `${BASE_URI}${
                    (riskPoolData as any).isRiskPoolRate ? GAIN_SHARE_FETCH_URI : GAIN_SHARE_TIERED_FETCH_URI
                }`,
                params,
            )
            .then((data) => {
                setFullHistory(data);
                setPendingHistory(data.filter((r) => !r.approvedAt || !r.approvedBy));
            })
            .catch((e) => {
                console.error(e);
                showErrorToast('Failed to fetch change history. Please try again later');
            })
            .finally(() => dialogService.loading(false));
    };

    const mapDataToGrid = (dataSource: RateChangeHistory[]) => {
        const result: RiskPoolChangeHistory[] = [];
        dataSource.forEach((record) => {
            if (record.tieredRatesDifferences?.length > 0) {
                record.tieredRatesDifferences.forEach((td) => {
                    td.differences.forEach((d) => {
                        if (d.name == 'effectiveStartDate' || d.name == 'effectiveEndDate') {
                            d.currentValue = d.currentValue ? getDateString(d.currentValue) : d.currentValue;
                            d.newValue = d.newValue ? getDateString(d.newValue) : d.newValue;
                        }
                        result.push({ ...record, ...d });
                    });
                });
            }
            if (record.differences?.length > 0) {
                record.differences.forEach((d) => {
                    if (d.name == 'effectiveStartDate' || d.name == 'effectiveEndDate') {
                        d.currentValue = d.currentValue ? getDateString(d.currentValue) : d.currentValue;
                        d.newValue = d.newValue ? getDateString(d.newValue) : d.newValue;
                    }
                    result.push({ ...record, ...d });
                });
            }
        });
        return result;
    };

    const getDateString = (dateTimeString: string) => {
        const dateTime = new Date(dateTimeString);
        return `${dateTime.getMonth() + 1}/${dateTime.getDate()}/${dateTime.getFullYear()}`;
    };

    const onApprove = (updatedDateStr = '') => {
        let requestBody;
        if (updatedDateStr) {
            const targetRecord = pendingHistory.find((r) => r.updatedAt === updatedDateStr);
            if (!targetRecord) return showErrorToast('Something went wrong');
            requestBody = [targetRecord.id];
        } else requestBody = pendingHistory.map((r) => r.id);

        setLoading(true);
        dialogService.loading(true);
        requests
            .put<RateChangeHistory[]>(
                `${BASE_URI}${
                    (riskPoolData as any).isRiskPoolRate ? GAIN_SHARE_APPROVE_URI : GAIN_SHARE_TIERED_APPROVE_URI
                }`,
                requestBody,
            )
            .then((data) => {
                showSuccessToast('Change(s) approved successfully');
                onApprovalSuccess(data);
                approvalCallback();
            })
            .catch((e) => {
                console.error(e);
                showErrorToast('Failed to approve change(s). Please try again later');
            })
            .finally(() => {
                setLoading(false);
                dialogService.loading(false);
            });
    };

    const onApproveAll = async () => {
        const shouldContinue = await showConfirmation({
            title: 'Approve All Confirmation',
            message: 'Are you sure you want to approve all pending changes?',
        });
        if (shouldContinue) onApprove();
    };

    const onApprovalSuccess = (approvedRecords: RateChangeHistory[]) => {
        const approvedRecordsDict = {};
        approvedRecords.forEach((r) => {
            approvedRecordsDict[r.id] = r;
        });
        if (approvedRecords.length === 1) {
            setPendingHistory((records) => records.filter((r) => r.id !== approvedRecords[0].id));
            setFullHistory((records) => records.map((r) => (r.id === approvedRecords[0].id ? approvedRecords[0] : r)));
        } else {
            setPendingHistory([]);
            setFullHistory((records) =>
                records.map((r) => (approvedRecordsDict[r.id] ? approvedRecordsDict[r.id] : r)),
            );
        }
    };

    useEffect(() => {
        fetchChangeHistory();
        setPendingSelection(riskPoolData);
        return () => setPendingSelection(null);
    }, []);

    useEffect(
        () => setDataSource(mapDataToGrid(viewMode === HistoryViewMode.All ? fullHistory : pendingHistory)),
        [viewMode, fullHistory, pendingHistory],
    );

    return (
        <DisabledWrapper disabled={loading}>
            <div className={styles.historyLabelsGroup}>
                <RadioButtonComponent
                    name="risk-pool-history"
                    label={HistoryViewMode.Pending}
                    cssClass={`${styles.historyLabel} me-5`}
                    checked={viewMode === HistoryViewMode.Pending}
                    change={() => setViewMode(HistoryViewMode.Pending)}
                />
                <RadioButtonComponent
                    name="risk-pool-history"
                    label={HistoryViewMode.All}
                    cssClass={styles.historyLabel}
                    checked={viewMode === HistoryViewMode.All}
                    change={() => setViewMode(HistoryViewMode.All)}
                />
            </div>

            <GridComponent
                height={320}
                rowHeight={40}
                allowGrouping
                allowSelection={false}
                columns={RiskPoolChangeHistoryColumns}
                groupSettings={{
                    columns: ['updatedAt'],
                    showDropArea: false,
                    captionTemplate: (args) => {
                        const item = args.items?.at(0) as RateChangeHistory;
                        if (!item) return;
                        return (
                            <div className="d-flex" title="">
                                <ChipListComponent
                                    cssClass={`pe-none risk-pool-history-caption ${
                                        item.approvedAt && item.approvedBy ? 'e-success' : ''
                                    }`}
                                >
                                    {item.updatedAt}
                                </ChipListComponent>
                                {item.approvedAt && item.approvedBy ? (
                                    <span
                                        style={{ color: '#22b24b' }}
                                        className="pe-none ms-3 d-flex align-items-center risk-pool-history-caption e-icons e-check"
                                    />
                                ) : (
                                    <ChipListComponent
                                        title="Click to approve"
                                        cssClass="e-warning e-outline risk-pool-history-caption"
                                        disabled={isReadOnlyViewer}
                                    >
                                        Approve
                                    </ChipListComponent>
                                )}
                            </div>
                        );
                    },
                }}
                dataBound={() => {
                    const captions = document.getElementsByClassName('risk-pool-history-caption');
                    let dateStr = '';
                    const dateStrDict = {};
                    for (let i = 0; i < captions.length; i++) {
                        if (i % 2 == 0) {
                            dateStr = captions[i].textContent ?? '';
                            dateStrDict[i.toString()] = dateStr;
                            captions[i].textContent = formatISOLocalDateTime(dateStr);
                        } else {
                            if (!isReadOnlyViewer)
                                captions[i].addEventListener('click', () => onApprove(dateStrDict[(i - 1).toString()]));
                            else captions[i].classList.add('e-disabled');
                        }
                    }
                }}
                dataSource={dataSource}
            >
                <Inject services={[Group]} />
            </GridComponent>

            <div className="mt-3 text-end">
                <button
                    type="button"
                    className="e-btn e-info text-capitalize"
                    style={{ width: '200px' }}
                    disabled={
                        isReadOnlyViewer ||
                        pendingHistory.length === 0 ||
                        pendingHistory.every((r) => r.approvedAt || r.approvedBy)
                    }
                    onClick={onApproveAll}
                >
                    Approve All
                </button>
            </div>
        </DisabledWrapper>
    );
};

export default RiskPoolRateChangeHistoryGrid;
