export const sendBegin = (submitAddAppointmentButton) => {
    submitAddAppointmentButton.current.progress();
};

export const handleProgressBtnStatus = (buttonRef, status, method) => {
    if (buttonRef?.current) {
        buttonRef.current.disabled = status;

        if (method) {
            buttonRef.current[method]();
        }
    }
};
