import React, { useRef, useEffect, useState } from 'react'
import { ODataV4Adaptor } from '@syncfusion/ej2-data';
import {
    DatePickerComponent,
    Inject,
    MaskedDateTime,
} from '@syncfusion/ej2-react-calendars';
import { CheckBoxSelection, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { useForm } from 'react-hook-form';
import { getAvaDataManager } from 'common/AVADataManager';
import { MemberEnrollmentApi } from 'components/AvaEzm/MemberEnrollment/MemberEnrollmentApi';
import GridTemplate from 'components/Common/GridTemplate';

import useToast from 'hooks/useToast';
import { AuditHistoryFilter, AuditHistoryGridColumns } from '../MemberEnrollmentDetails/MemberEnrollmentConfig';
import 'components/AvaEzm/MemberEnrollment/MemberEnrollmentDetails/EnrollmentDetails.scss'
import { formatISODate } from '../../../../common/widgets';

const AuditHistory = () => {
    const updateEnrollments = useRef<HTMLFormElement>(null)
    const loaderRef = useRef<HTMLDivElement>(null);
    const { showErrorToast } = useToast();
    const [auditHistoryData, setAuditHistoryData] = useState([])
    const [peRecordsCount, setPeRecordsCount] = useState(0)
    const [eaRecordsCount, setEaRecordsCount] = useState(0)
    const auditHistoryGridRef = useRef<GridTemplate>(null);

    const { register, handleSubmit, setValue, getValues } = useForm<AuditHistoryFilter>({
        mode: 'onChange',
        defaultValues: {
            Start: undefined,
            End: undefined,
            GroupUsers: [],
            Feature: '',
        },
    });

    const sortSettings = {
        columns: [
            {
                field: 'timestamp',
                direction: 'descending',
            },
        ],
        allowUnsort: false,
    };

    const tabsToolbarOptions = [
        'ColumnChooser',
    ];

    const showLoader = (show: boolean) => {
        if (loaderRef.current) {
            loaderRef.current.style.display = show ? 'block' : 'none';
        }
    };

    const getAuditHistory = async (filter) => {
        if (filter.Start) {
            filter.Start = filter.Start && formatISODate(filter.Start);
        } else {
            delete filter.Start;
        }
        if (filter.End) {
            filter.End = filter.End && formatISODate(filter.End);
        } else {
            delete filter.End;
        }

        const filters = { ...filter, "requiresCounts": true, "sorted": [{ "name": "timestamp", "direction": "descending" }] }
        await MemberEnrollmentApi.GetMemberEnrollmentProcessingAuditHistory(filters).then((res) => {
            setAuditHistoryData(res);
            if (auditHistoryGridRef.current) auditHistoryGridRef.current.gridInstance.dataSource = res.result;
        }).catch(() => {
            showErrorToast('Something went wrong.');
        });
    }

    const getAuditHistoryFeatureCount = async (filter) => {
        const filters = { ...filter, "requiresCounts": true, "sorted": [{ "name": "timestamp", "direction": "descending" }], "skip": 0, "take": 0 }
        await MemberEnrollmentApi.GetMemberEnrollmentAuditHistoryFeatureCount(filters).then((res) => {
            if (filter.Feature === 'PaperEnrollment') {
                setPeRecordsCount(res?.peCount);
            }
            else if (filter.Feature === 'EnrollmentAutomation') {
                setEaRecordsCount(res?.eaCount);
            }
            else {
                setPeRecordsCount(res?.peCount);
                setEaRecordsCount(res?.eaCount);
            }
        }).catch(() => {
            showErrorToast('Something went wrong.');
        });
    }

    const loadAuditHistoryData = (filter) => {
        showLoader(true);
        Promise.all([
            getAuditHistory(filter),
            getAuditHistoryFeatureCount(filter),
        ]).finally(() => {
            showLoader(false);
        });
    }

    const onSubmit = (data, e) => {
        e.preventDefault();
        setValue('Feature', '');
        delete data.Feature;
        loadAuditHistoryData(data);
    };

    useEffect(() => {
        loadAuditHistoryData({});
    }, [])

    return (
        <div className="searchCriteriaContainer auditHistoryContainer" style={{ border: 'none' }}>
            <div ref={loaderRef}>
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        position: 'absolute',
                        zIndex: '99990',
                        backdropFilter: 'blur(2px)',
                    }}
                ></div>
                <div
                    className="spinner-border"
                    style={{
                        position: 'absolute',
                        top: '47vh',
                        left: 'calc(50% - 20px)',
                        height: '50px',
                        width: '50px',
                        zIndex: '99999',
                        color: 'rgb(227, 22, 91)',
                    }}
                ></div>
            </div>
            <form ref={updateEnrollments} onSubmit={handleSubmit(onSubmit)}>
                <div className="row p-3">
                    <div className="col-md-2"></div>
                    <div className="col-md-2">
                        <DatePickerComponent
                            format="MM/dd/yyyy"
                            {...register('Start')}
                            min={undefined}
                            max={undefined}
                            cssClass="bg-white"
                            enableMask={true}
                        >
                            <Inject services={[MaskedDateTime]} />
                        </DatePickerComponent>
                    </div>
                    <div className="col-md-2">
                        <DatePickerComponent
                            format="MM/dd/yyyy"
                            {...register('End')}
                            min={getValues('Start')}
                            max={undefined}
                            cssClass="bg-white"
                            enableMask={true}
                        >
                            <Inject services={[MaskedDateTime]} />
                        </DatePickerComponent>
                    </div>
                    <div className="col-md-3">
                        <MultiSelectComponent
                            id="checkbox"
                            dataSource={getAvaDataManager('/api/filters/groupusers?$top=100', ODataV4Adaptor)}
                            placeholder="User ID / Email"
                            mode="CheckBox"
                            {...register('GroupUsers')}
                            fields={{ text: 'name', value: 'id' }}
                        >
                            <Inject services={[CheckBoxSelection]} />
                        </MultiSelectComponent>

                    </div>
                    <div className="col-md-2">
                        <input type="submit" className="btn-primary mx-2 customBtn" style={{ color: '#fff' }} value="Search" />
                    </div>
                </div>
            </form>
            <div className='tilesContainer'>
                <div className="statButtonWrapper">
                    <button className={`statButton button-card ${getValues('Feature') === 'PaperEnrollment' && 'activeTiles'} `} onClick={() => {
                        setValue('Feature', 'PaperEnrollment');
                        loadAuditHistoryData(getValues());
                    }}>
                        <div className="statButtonValue">{peRecordsCount }</div>
                        <div className="statButtonTitle">Paper Enrollment</div>
                    </button>
                </div>
                <div className="statButtonWrapper">
                    <button className={`statButton button-card ${getValues('Feature') === 'EnrollmentAutomation' && 'activeTiles'} `} onClick={() => {
                        setValue('Feature', 'EnrollmentAutomation');
                        loadAuditHistoryData(getValues());
                    }}>
                        <div className="statButtonValue">{eaRecordsCount}</div>
                        <div className="statButtonTitle">Web Enrollment</div>
                    </button>
                </div>
            </div>
            <div className="row" style={{ margin: 10, width: 'calc(100% - 20px)' }}>
                <div style={{ width: '100%' }}>
                    <GridTemplate
                        ref={auditHistoryGridRef}
                        sortSettings={sortSettings}
                        height={450}
                        allowPaging={true}
                        pageSizes={[25, 50, 100]}
                        columns={AuditHistoryGridColumns}
                        toolbarOptions={tabsToolbarOptions}
                        dataSource={auditHistoryData}
                        autoFitColumns={false}
                        allowTextWrap={false}
                    />
                </div>
            </div>
        </div>
    )
}

export default AuditHistory;