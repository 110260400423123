import { useCallback, useEffect, useState } from 'react';
import { currentUserService } from 'subjects/common/CurrentUserService';

const useUserGroups = () => {
    const [myGroups, setMyGroups] = useState<string[]>([]);

    const belongToGroupName = useCallback(
        (name: string) => myGroups?.findIndex((groupName) => groupName === name) >= 0,
        [myGroups]
    );

    useEffect(() => {
        const currentUserSubscription = currentUserService.get().subscribe((user: any) => {
            setMyGroups(user.userGroups);
        });
        return () => currentUserSubscription.unsubscribe();
    }, []);

    return { groups: myGroups, belongToGroupName };
};

export default useUserGroups;
