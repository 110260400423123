import React, { useCallback, useEffect, useState } from 'react';
import {
    faCheckCircle,
    faTimesCircle,
    faExclamationCircle,
    faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Toast } from '@syncfusion/ej2-notifications';
import { renderToStaticMarkup } from 'react-dom/server';

import styles from 'css/useToast.module.scss';

const toastStatus = {
    success: 'success',
    error: 'error',
    warning: 'warning',
    info: 'info',
};
const toastContainerId = 'use-toast-hook';

const useToast = (parentId: string | null = null) => {
    const [toast, setToast] = useState<Toast | null>(null);
    const [containerId] = useState(parentId ?? toastContainerId);

    const createToastContainer = () => {
        const toastContainer = document.createElement('div');
        document.body.appendChild(toastContainer);
        toastContainer.setAttribute('id', containerId);
        toastContainer.setAttribute('style', parentId ? 'right: 0' : 'right: 15px');
    };

    const initializeToast = () => {
        const existingToastContainer = document.getElementById(containerId);
        if (!existingToastContainer) createToastContainer();
        const toast: Toast = new Toast({
            showCloseButton: true,
            position: { Y: parentId ? 0 : 65, X: 'Right' },
            target: parentId ? '#' + parentId : undefined,
        });
        toast.appendTo('#' + containerId);
        setToast(toast);
    };

    useEffect(() => {
        initializeToast();
        return () => toast?.destroy();
    }, []);

    const _getStatusIcon = (status: string) => {
        switch (status) {
            case toastStatus.success:
                return <FontAwesomeIcon className={styles.iconSuccess} icon={faCheckCircle} />;
            case toastStatus.error:
                return <FontAwesomeIcon className={styles.iconError} icon={faTimesCircle} />;
            case toastStatus.info:
                return <FontAwesomeIcon className={styles.iconInfo} icon={faExclamationCircle} />;
            case toastStatus.warning:
                return <FontAwesomeIcon className={styles.iconWarning} icon={faExclamationTriangle} />;
            default:
                return null;
        }
    };

    const _customizeMessage = useCallback((status: string, title: string, content: string) => {
        const statusIcon = _getStatusIcon(status);
        return (
            <div className={styles.content}>
                <div className={styles.header}>
                    {statusIcon}
                    <span className={styles.title}>
                        <b>{title}</b>
                    </span>
                </div>
                <span className={styles.text}>{content}</span>
            </div>
        );
    }, []);

    const _getHtmlContent = useCallback(
        (status: string, title: string, content: string) => {
            const output = document.createElement('div');
            if (typeof content !== 'string') content = 'Toast message is not a string, please recheck your code';
            const element = _customizeMessage(status, title, content);
            const staticElement = renderToStaticMarkup(element);
            output.innerHTML = `<div>${staticElement}</div>`;
            return output;
        },
        [_customizeMessage]
    );

    const showSuccessToast = useCallback(
        (content = 'default toast content text', title = 'Success', timeOut = 5000) => {
            toast?.show({
                cssClass: `${styles.toast} ${styles.toastSuccess}`,
                content: _getHtmlContent(toastStatus.success, title, content),
                timeOut,
            });
        },
        [_getHtmlContent, toast]
    );

    const showErrorToast = useCallback(
        (content = 'default toast content text', title = 'Error', timeOut = 5000) => {
            toast?.show({
                cssClass: `${styles.toast} ${styles.toastError}`,
                content: _getHtmlContent(toastStatus.error, title, content),
                timeOut,
            });
        },
        [_getHtmlContent, toast]
    );

    const showWarningToast = useCallback(
        (content = 'default toast content text', title = 'Warning', timeOut = 5000) => {
            toast?.show({
                cssClass: `${styles.toast} ${styles.toastWarning}`,
                content: _getHtmlContent(toastStatus.warning, title, content),
                timeOut,
            });
        },
        [_getHtmlContent, toast]
    );

    const showInfoToast = useCallback(
        (content = 'default toast content text', title = 'Information', timeOut = 8000) => {
            toast?.show({
                cssClass: `${styles.toast} ${styles.toastInfo}`,
                content: _getHtmlContent(toastStatus.info, title, content),
                timeOut,
            });
        },
        [_getHtmlContent, toast]
    );

    return { showSuccessToast, showErrorToast, showInfoToast, showWarningToast };
};

export default useToast;
