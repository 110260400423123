import { useEffect, useState } from 'react';
import { Subscription } from 'rxjs';

function useUnsubscribe() {
    const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
    useEffect(
        () => () => {
            subscriptions.forEach((subscription) => {
                if (subscription?.unsubscribe) {
                    subscription.unsubscribe();
                }
            });
        },
        [subscriptions],
    );

    function pushSubscription(subscription) {
        setSubscriptions((currentSubscriptions) => [...currentSubscriptions, subscription]);
    }
    return pushSubscription;
}

export default useUnsubscribe;
